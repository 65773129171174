import {
  InitAppendedDFProcessPayload,
  InitPDFProcessPayload,
  calculatePollingTime,
} from '@gohighlevel/ghl-proposals-common'
import axios, {
  AxiosError,
  AxiosProgressEvent,
  CancelTokenSource,
  isAxiosError,
} from 'axios'

import { UploadPdfService } from '@/service/UploadPdfService'
import { ref } from 'vue'
import { useDocument } from './useDocument'
import { useI18n } from 'vue-i18n'
import { useNotify } from './useNotify'
import { useRoute } from 'vue-router'
import { v4 as uuid } from 'uuid'

const isUploading = ref(false)
const isProcessing = ref(false)
const uploadPercentage = ref(0)
const errorMessage = ref(null)
const isRedirecting = ref(false)
const isRedirected = ref(false)
const processingPercentage = ref(0)
const attemptId = ref('')
const pollingTime = ref(5000) // 5 seconds
const uploadCancelTokenSources: CancelTokenSource[] = []
const processCancelTokenSources: CancelTokenSource[] = []

export const useUploadPDF = () => {
  const { t } = useI18n()
  const route = useRoute()
  const { updatePagesAfterUpload } = useDocument()
  const { handleSuccess } = useNotify()
  const locationId = route.params.locationId as string
  let timeout = null

  const isCancelled = ref(false)
  const createCancelTokenSource = () => axios.CancelToken.source()

  const resetErrorMessage = async () => {
    errorMessage.value = null
  }

  const cancelUpload = async () => {
    if (uploadCancelTokenSources.length) {
      uploadCancelTokenSources.forEach(source => {
        source.cancel(t('validations.warnings.uploadCancelled'))
      })
      isUploading.value = false
    }
  }

  const uploadPDF = async (
    file: File,
    documentId: string,
    order: number,
    onUploadProgress: (progressEvent: AxiosProgressEvent) => void
  ) => {
    try {
      attemptId.value = uuid()
      let fileValidation = true
      const cancelToken = createCancelTokenSource()
      uploadCancelTokenSources.push(cancelToken)
      resetErrorMessage()
      isUploading.value = true
      clearTimeout(timeout)
      isProcessing.value = false
      isRedirecting.value = false
      isRedirected.value = false

      if (file?.size && file?.size > 50000000) {
        const msg = t('validations.warnings.fileExceedsLimit')
        errorMessage.value = msg
        fileValidation = false
        isUploading.value = false
      }

      if (fileValidation) {
        const { data } = await UploadPdfService.uploadPdf({
          locationId,
          file,
          documentId,
          order,
          attemptId: attemptId.value,
          onUploadProgress: onUploadProgress,
          cancelToken: cancelToken.token,
        })
        return data
      }
    } catch (error) {
      isUploading.value = false
      if (axios.isCancel(error)) {
        console.error('Request canceled:', error.message)
      }
      if (isAxiosError(error)) {
        const axiosError = error as AxiosError
        if (axiosError?.response?.data) {
          const msg =
            (axiosError?.response?.data?.message as string) === 'File too large'
              ? t('validations.warnings.fileExceedsLimit')
              : axiosError?.response?.data?.message
          errorMessage.value = msg
        }
      }
    }
  }

  const initFileProcessing = async (payload: InitPDFProcessPayload) => {
    try {
      const { data } = await UploadPdfService.startProcessing(payload)
      if (data.documentId) {
        checkForProcessing(data._id, data.documentId)
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.error('Request canceled:', error.message)
      }
      if (isAxiosError(error)) {
        const axiosError = error as AxiosError
        if (axiosError?.response?.data) {
          errorMessage.value = axiosError?.response?.data?.message
        }
      }
    }
  }

  const initAppendedFileProcessing = async (
    payload: InitAppendedDFProcessPayload
  ) => {
    try {
      if (!payload.attemptId) {
        payload.attemptId = attemptId.value
      }
      const { data } = await UploadPdfService.startAppendedPDFProcessing(
        payload
      )
      if (data.documentId) {
        checkForProcessing(data._id, data.documentId)
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.error('Request canceled:', error.message)
      }
      if (isAxiosError(error)) {
        const axiosError = error as AxiosError
        if (axiosError?.response?.data) {
          errorMessage.value = axiosError?.response?.data?.message
        }
      }
    }
  }

  const checkForProcessing = async (id: string, documentId: string) => {
    try {
      isProcessing.value = true
      isUploading.value = true
      const cancelToken = createCancelTokenSource()
      uploadCancelTokenSources.push(cancelToken)
      const { data } = await UploadPdfService.checkPdfStatus(id, locationId, {
        cancelToken: cancelToken.token,
      })
      if (data.error) {
        clearTimeout(timeout)
        errorMessage.value = t('validations.warnings.pdfFileError')
        isProcessing.value = false
        isRedirecting.value = false
        isUploading.value = false
        return
      }
      if (!data.status) {
        if (data.meta && data.meta.size) {
          pollingTime.value = calculatePollingTime(
            data.meta.pageCount,
            1,
            2000,
            15000
          )
          console.info(`Polling time: ${pollingTime.value}ms`)
          processingPercentage.value = Math.round(
            (data.meta.processedPageCount / data.meta.pageCount) * 100
          )
        }
        clearTimeout(timeout)
        timeout = setTimeout(() => {
          checkForProcessing(id, documentId)
        }, pollingTime.value)
      } else {
        processingPercentage.value = 100
        isRedirecting.value = true
        clearTimeout(timeout)
        timeout = setTimeout(async () => {
          try {
            await updatePagesAfterUpload()
            isRedirecting.value = false
            await cancelProcessing()
            isRedirected.value = true
            handleSuccess(t(`PDF's merged successfully!!`))
          } catch (error) {
            setError(t('validations.warnings.updatePagesError'))
          }
        }, 3000)
      }
    } catch (error) {
      console.error(error)
      cancelProcessing()
    }
  }

  const cancelProcessing = async () => {
    clearTimeout(timeout)
    isCancelled.value = true
    isProcessing.value = false
    isRedirecting.value = false
    isUploading.value = false
    isRedirected.value = false
    errorMessage.value = null
    processingPercentage.value = 0
    if (processCancelTokenSources.length) {
      processCancelTokenSources.forEach(source => {
        source.cancel(t('validations.warnings.uploadCancelled'))
      })
    }
  }

  const setError = (message: string) => {
    errorMessage.value = message
  }

  return {
    uploadPDF,
    checkForProcessing,
    isUploading,
    isProcessing,
    uploadPercentage,
    resetErrorMessage,
    errorMessage,
    isRedirecting,
    cancelProcessing,
    isRedirected,
    setError,
    processingPercentage,
    cancelUpload,
    initFileProcessing,
    initAppendedFileProcessing,
  }
}
