<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { useBuilderStore } from '@/store/builder'
import {
  Copy01Icon,
  DotsHorizontalIcon,
  Settings01Icon,
  Trash02Icon,
  PlusIcon,
  Upload01Icon,
} from '@gohighlevel/ghl-icons/24/outline'
import { renderIcon, UIButton, UIDropdown } from '@gohighlevel/ghl-ui'
import { computed, ref } from 'vue'
import PageRenderer from './PageRenderer.vue'
import { IElement, WithCommonProvider } from '@gohighlevel/ghl-proposals-common'
import { usePropertiesDrawer } from '@/composable/usePropertiesDrawer'
import { SelectoComponentWrapper } from '@gohighlevel/ghl-proposals-common'
import { useGroups } from '@/composition/useGroups'
import AppendedFileUploadModal from '../common/AppendedFileUploadModal.vue'
import { useRoute } from 'vue-router'

const { t } = useI18n()
const store = useBuilderStore()
const {
  groupTargets,
  unGroupTargets,
  deleteGroupElements,
  cloneGroupElements,
  setGroupTargetsOnSelection,
  onCloneAndAddToGroupByGroupId,
} = useGroups()
const { setOpen } = usePropertiesDrawer()
const route = useRoute()
const pages = computed(() => store.pages)
const activeGroup = computed(() => store.activeGroup)
const pageWrapperRef = ref<HTMLElement | null>()
const appendImagesFromIndex = ref(0)
const showUploadModal = ref(false)
const SelectoFieldComponent = WithCommonProvider(SelectoComponentWrapper)

const getOptions = (page: IElement) => {
  const deleteOption = {
    label: t('common.deletePage'),
    key: 'delete_page',
    icon: renderIcon(Trash02Icon),
  }

  const addPdfOption = {
    label: t('common.addPdfs'),
    key: 'add_pdf',
    icon: renderIcon(Upload01Icon),
  }

  if (page.component.options.fromPDF) {
    return [deleteOption, addPdfOption]
  }
  const options = [
    {
      label: t('common.pageProperties'),
      key: 'page_properties',
      icon: renderIcon(Settings01Icon),
    },
    {
      label: t('common.duplicatePage'),
      key: 'duplicate_page',
      icon: renderIcon(Copy01Icon),
    },
  ]
  if (pages.value.length > 1) {
    options.push(deleteOption)
  }

  options.push(addPdfOption)
  return options
}

function onSelect(option: string, page: IElement) {
  switch (option) {
    case 'page_properties':
      store.updateActiveElement(page)
      setOpen()
      break
    case 'duplicate_page':
      store.duplicatePage(page.id)
      break
    case 'delete_page':
      store.deletePage(page.id)
      break
    case 'add_pdf':
      addPdfToPage(page.id)
      break
  }
}

function addPdfToPage(pageId: string) {
  const index = pages.value.findIndex(p => p.id === pageId)
  if (index === -1) return
  appendImagesFromIndex.value = index
  showUploadModal.value = true
}

const onSelectoSelectEnd = (event: any) => {
  if (event.targets.length > 1) {
    if (event.selectedElements)
      setGroupTargetsOnSelection(event.selectedElements)
  }
  if (event.targets.length === 0) {
    setGroupTargetsOnSelection([])
  }
}
</script>

<template>
  <div class="pages-wrapper relative" ref="pageWrapperRef">
    <div class="absolute">
      <div v-for="(page, index) in pages" :key="index" class="page">
        <div class="flex justify-between items-center justify-items-center">
          <div
            :style="`height: ${store.isDocumentEditable ? '0' : '30'}px;`"
          ></div>
          <UIButton
            class="my-4"
            v-if="store.isDocumentEditable"
            id="add_new_page"
            circle
            quaternary
            size="large"
            @click="store.addPage(index - 1)"
          >
            <PlusIcon class="w-6 h-6 stroke-gray-500" />
          </UIButton>
          <div
            v-if="store.isDocumentEditable"
            class="flex"
            :class="{
              'pointer-events-none opacity-50 cursor-not-allowed':
                page.component.options.fromPDF && pages.length <= 1,
            }"
          >
            <UIDropdown
              id="page_settings"
              :options="getOptions(page)"
              @select="val => onSelect(val, page)"
            >
              <DotsHorizontalIcon
                class="w-6 h-6 stroke-gray-500 cursor-pointer"
              />
            </UIDropdown>
          </div>
        </div>

        <PageRenderer
          :id="`page-${page.id}`"
          :key="page.id"
          :elements="page.children"
          :page="page"
          :pageIndex="index"
        />
        <div
          :class="`flex items-center justify-items-center ${
            pages.length > 0 && index === pages.length - 1
              ? 'justify-center'
              : 'justify-between'
          }`"
          v-if="pages.length > 0 && index === pages.length - 1"
        >
          <div
            :style="`height: ${store.isDocumentEditable ? '0' : '30'}px;`"
          ></div>
          <UIButton
            class="my-4"
            v-if="store.isDocumentEditable"
            id="add_new_page"
            circle
            quaternary
            size="large"
            @click="store.addPage(index + 1)"
          >
            <PlusIcon class="w-6 h-6 stroke-gray-500" />
          </UIButton>
          <div
            v-if="store.isDocumentEditable && pages.length > index + 1"
            class="flex"
          >
            <UIDropdown
              id="page_settings"
              :options="getOptions(page)"
              @select="val => onSelect(val, page)"
            >
              <DotsHorizontalIcon
                class="w-6 h-6 text-gray-700 cursor-pointer"
              />
            </UIDropdown>
          </div>
        </div>
      </div>
      <SelectoFieldComponent
        @on-selecto-select-end="onSelectoSelectEnd"
        @on-selecto-delete="deleteGroupElements"
        @on-selecto-clone="cloneGroupElements"
        @on-selecto-group="groupTargets"
        @on-selecto-ungroup="unGroupTargets"
        @on-selecto-clone-add-to-group="onCloneAndAddToGroupByGroupId"
        :activeGroup="activeGroup"
      />
    </div>
    <AppendedFileUploadModal
      v-if="showUploadModal"
      :show="showUploadModal"
      @close-modal="showUploadModal = false"
      :isAppend="true"
      :appendImagesFromIndex="appendImagesFromIndex"
      :documentId="
        Array.isArray(route.params.documentId)
          ? route.params.documentId[0]
          : route.params.documentId
      "
    />
  </div>
</template>

<style lang="scss">
.pages-wrapper {
  .page {
    &:last-child {
      margin-bottom: 30px;
    }
  }
}
</style>
