/**
 * Utility functions for formatting and comparing byte sizes.
 */
export const bytes = {
  /**
   * Formats a given number of bytes into a human-readable string with appropriate units.
   *
   * @param bytes - The number of bytes to format.
   * @returns A string representing the formatted byte size with appropriate units (Bytes, KB, MB, GB, TB).
   */
  format: (bytes: number) => {
    if (bytes === 0) return '0 Bytes'

    const units = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    const k = 1024
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    const formattedValue = (bytes / Math.pow(k, i)).toFixed(2)
    return `${formattedValue} ${units[i]}`
  },

  /**
   * Checks if a given number of bytes is greater than or equal to a specified limit in megabytes.
   *
   * @param bytes - The number of bytes to compare.
   * @param limitInMB - The limit in megabytes to compare against.
   * @returns `true` if the number of bytes is greater than or equal to the limit in megabytes, otherwise `false`.
   */
  isGreaterThanOrEq: (bytes: number, limitInMB: number) => {
    const limitInBytes = limitInMB * 1024 * 1024
    return bytes >= limitInBytes
  },
}
