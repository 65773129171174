<script setup lang="ts">
import { computed, ref, watch, onMounted } from 'vue'
import {
  CustomModal,
  getFormatDate,
  ModalEvents,
  addDaysToDateTime,
} from '@gohighlevel/ghl-proposals-common'
import { useAppStore } from '@/store/app'
import {
  UIInputNumber,
  UICheckbox,
  UISwitch,
  UITextSmNormal,
  UITextMdMedium,
  UIFormItem,
} from '@gohighlevel/ghl-ui'

const emit = defineEmits([ModalEvents.ON_SUCCESS, ModalEvents.ON_CANCEL])
const DefaultExpiryDays = 60

const appStore = useAppStore()
const expiryDays = ref(appStore.document.expiryDays || DefaultExpiryDays)
const setExpiryWarning = ref(appStore.document.isExpiryReminderEnabled || false)
const computedExpiryDate = ref('')

const enableExpiryToggle = ref(appStore.document.isExpiryEnabled || false)
const enableExpiryReminderToggle = ref(
  appStore.document.isExpiryReminderEnabled || false
)

// Add a method to update the expiry date
const updateExpiryDate = () => {
  if (expiryDays.value) {
    computedExpiryDate.value = addDaysToDateTime(
      expiryDays.value,
      appStore.timeZone
    )
  }
}

// Watch for changes in expiryDays to update the date
watch(expiryDays, () => {
  updateExpiryDate()
})

// Watch for changes in the toggle to update the date
watch(enableExpiryToggle, newValue => {
  if (newValue) {
    updateExpiryDate()
  }
})

// Update the date when the modal is mounted
onMounted(() => {
  updateExpiryDate()
})

const isDisabled = computed(() => {
  return (
    enableExpiryToggle.value && (!expiryDays.value || expiryDays.value <= 0)
  )
})

const validationRules = {
  expiryDays: {
    trigger: ['change', 'blur', 'focus'],
    validator() {
      // Check if the value is a decimal number
      if (!Number.isInteger(expiryDays.value)) {
        return new Error('')
      }

      // Check if value is positive
      if (expiryDays.value <= 0) {
        return new Error('')
      }

      return true
    },
  },
}

function setExpiry() {
  appStore.setExpiryStatuses(
    computedExpiryDate.value,
    expiryDays.value,
    enableExpiryToggle.value,
    enableExpiryReminderToggle.value
  )
}

// Update the onCancel handler to reset values to original state
const onCancel = () => {
  // Reset all values to their original state from appStore
  expiryDays.value = appStore.document.expiryDays || DefaultExpiryDays
  enableExpiryToggle.value = appStore.document.isExpiryEnabled || false
  enableExpiryReminderToggle.value =
    appStore.document.isExpiryReminderEnabled || false
  setExpiryWarning.value = appStore.document.isExpiryReminderEnabled || false
  updateExpiryDate()
  emit(ModalEvents.ON_CANCEL)
}

// Update the date before emitting success
const onSuccess = () => {
  updateExpiryDate()
  setExpiry()
  emit(ModalEvents.ON_SUCCESS)
}

const toggleDocumentExpirySetting = (value: boolean) => {
  enableExpiryToggle.value = value
}

const toggleDocumentExpiryReminderSetting = (value: boolean) => {
  enableExpiryReminderToggle.value = value
}
</script>

<template>
  <CustomModal
    :close-on-success="true"
    :headerIcon="false"
    :disabled="isDisabled"
    :title="`${$t('common.expirySettings')}`"
    :description="''"
    @on-cancel="onCancel"
    @on-success="onSuccess"
    :cancelActionText="$t('common.cancel')"
    :successActionText="$t('common.apply')"
    :modalType="'success'"
  >
    <div class="flex justify-between items-center w-full pb-4">
      <div>
        <UITextMdMedium>{{ $t('common.enableExpirySettings') }}</UITextMdMedium>
        <UITextSmNormal>{{
          $t('common.expirationSettingsDescription')
        }}</UITextSmNormal>
      </div>
      <UISwitch
        :value="enableExpiryToggle"
        @update:value="val => toggleDocumentExpirySetting(val)"
      />
    </div>
    <div class="flex flex-col">
      <!-- Expiry Date Row -->
      <div class="flex items-center mb-3">
        <label class="mr-2 flex items-center">{{
          $t('common.expirationSettingsInput')
        }}</label>
        <div class="flex items-center">
          <UIFormItem
            :rule="validationRules.expiryDays"
            :showLabel="false"
            class="flex items-center"
          >
            <UIInputNumber
              id="expiry-days"
              v-model="expiryDays"
              :disabled="!enableExpiryToggle"
              placeholder=""
              class="rounded-md focused w-12"
              size="medium"
              :show-button="false"
              :min="1"
              :step="1"
            />
          </UIFormItem>
        </div>
        <span class="ml-2 flex items-center">{{ $t('common.days') }}</span>
      </div>
      <!-- Helper Text -->
      <div
        class="text-sm text-gray-500 mb-3"
        v-if="expiryDays > 0 && enableExpiryToggle"
      >
        {{ $t('common.expirationSettingsHelperText') }}
        {{
          getFormatDate(
            computedExpiryDate,
            'D MMMM YYYY hh:mm A',
            appStore.timeZone
          )
        }}
      </div>
      <!-- Warning Email Header -->
      <div class="font-bold mb-2">{{ $t('common.warningEmail') }}</div>
      <!-- Warning Email Checkbox -->
      <div class="flex items-center mb-3">
        <UICheckbox
          id="expiry-warning-check"
          v-model:checked="setExpiryWarning"
          :disabled="!enableExpiryToggle"
          @update:checked="val => toggleDocumentExpiryReminderSetting(val)"
        />
        <label for="warnSigners" class="ml-2">{{
          $t('common.warningEmailDescription')
        }}</label>
      </div>
    </div>
  </CustomModal>
</template>
