import { AxiosRequestConfig, CancelToken } from 'axios'
import {
  InitAppendedDFProcessPayload,
  InitPDFProcessPayload,
} from '@gohighlevel/ghl-proposals-common'

import config from '@/config'
import { requests } from '.'

export const UploadPdfService = {
  uploadPdf: ({
    file,
    locationId,
    onUploadProgress,
    cancelToken,
    documentId,
    order,
    attemptId,
  }: {
    locationId: string
    documentId: string
    file: File
    order: number
    attemptId: string
    onUploadProgress: AxiosRequestConfig['onUploadProgress']
    cancelToken: CancelToken
  }) => {
    const formData = new FormData()
    formData.append('file', file)
    return requests(config.PROPOSAL_SERVICE_URL).post(
      `/${locationId}/upload-pdf?documentId=${documentId}&order=${order}&attemptId=${attemptId}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: ev => {
          onUploadProgress(ev)
        },
        cancelToken,
      }
    )
  },
  checkPdfStatus: (id: string, locationId: string) =>
    requests(config.PROPOSAL_SERVICE_URL).get(
      `/check-pdf-status/${id}?locationId=${locationId}`
    ),

  startProcessing: (payload: InitPDFProcessPayload) =>
    requests(config.PROPOSAL_SERVICE_URL).post(`/process-pdf`, payload),

  startAppendedPDFProcessing: (payload: InitAppendedDFProcessPayload) =>
    requests(config.PROPOSAL_SERVICE_URL).post(
      `/process-appended-pdf`,
      payload
    ),
}
