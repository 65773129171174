/**
 *
 * @param totalPages
 * @param pagesPerSecond
 * @param minPollingTime in milliseconds
 * @param maxPollingTime in milliseconds
 * @returns
 */
export function calculatePollingTime(
  totalPages: number,
  pagesPerSecond: number,
  minPollingTime: number,
  maxPollingTime: number
): number {
  const pollingTimeInSeconds = totalPages / pagesPerSecond
  const pollingTime = pollingTimeInSeconds * 1000 // Convert to milliseconds

  // Ensure polling time is within the specified range
  return Math.min(Math.max(pollingTime, minPollingTime), maxPollingTime)
}
