<script lang="ts" setup>
import { UITextMdMedium } from '@gohighlevel/ghl-ui'
import { computed, PropType, ref, watch } from 'vue'
import { IElement, ELEMENTS_STYLES } from '@gohighlevel/ghl-proposals-common'
import { useElementOption } from '@/composable/elementOptions'
import { debounce } from 'lodash'

const { updateStyles } = useElementOption()
const imageElement = ref()

const props = defineProps({
  element: {
    type: Object as PropType<IElement>,
  },
})
const customClassesForImg = computed(() => {
  const styles = props.element?.responsiveStyles.large
  return [styles?.align, styles?.imageEffect]
})

const imageSrc = computed(() => props.element?.component?.options?.src)

const customStyleForImageParent = computed(() => {
  const styles = props.element?.responsiveStyles.large
  return {
    backgroundColor: styles?.backgroundColor,
    marginTop: styles?.marginTop,
    marginBottom: styles?.marginBottom,
    marginLeft: styles?.marginLeft,
    marginRight: styles?.marginRight,
    paddingTop: styles?.paddingTop,
    paddingBottom: styles?.paddingBottom,
    paddingLeft: styles?.paddingLeft,
    paddingRight: styles?.paddingRight,
  }
})

const updateStyleForImageElement = (key: string, value: string) => {
  updateStyles(key, value, props?.element?.id, props?.element?.type)
}

const updateHeightAndWidth = () => {
  const imgSrc = imageElement.value?.getBoundingClientRect()
  if (imgSrc?.height) {
    updateStyleForImageElement(ELEMENTS_STYLES.HEIGHT, imgSrc.height.toFixed(2))
  }

  if (imgSrc?.width) {
    updateStyleForImageElement(ELEMENTS_STYLES.WIDTH, imgSrc.width.toFixed(2))
  }
}
/*
 * height and width should not be empty.
 * if its empty the calculation of offsite in PDF generation will be wrong and because of that,
 * the content will be eliminated from the PDF - So we should have the height & width
 */
watch(imageSrc, newValue => {
  if (newValue) {
    updateStyleForImageElement(ELEMENTS_STYLES.HEIGHT, '')
    updateStyleForImageElement(ELEMENTS_STYLES.WIDTH, '')
  }
})

const onImgLoad = () => {
  debounce(updateHeightAndWidth, 500)()
}
</script>

<template>
  <div
    class="el-wrapper img-el"
    type="content"
    kind="image"
    ref="imageElementWrapper"
  >
    <div
      v-if="element?.component?.options?.src"
      :style="customStyleForImageParent"
    >
      <img
        draggable="false"
        ref="imageElement"
        :alt="element?.component?.options?.altText"
        :class="customClassesForImg"
        :height="element?.responsiveStyles.large.height"
        :src="element?.component?.options?.src"
        :width="element?.responsiveStyles.large.width"
        class="img"
        @load="onImgLoad"
        aria-hidden="true"
      />
    </div>
    <div
      v-else
      class="h-[100px] bg-gray-100 text-center text-lg relative overflow-auto m-[2px]"
    >
      <UITextMdMedium
        class="break-words -translate-y-2/4 absolute left-0 top-[50%] w-full"
        >{{ $t('common.pleaseSelectImage') }}</UITextMdMedium
      >
    </div>
  </div>
</template>

<style lang="scss">
.el-wrapper {
  .img {
    max-width: 100%;
    max-height: 100%;
    display: block;
  }
}
</style>
