<script setup lang="ts">
import { CustomModal } from '../CustomModal'
import {
  CCSendDocumentModalEvents,
  ModalEvents,
  ProposalsMailTemplates,
  SendDocumentsContractAction,
} from '../../../types/components'
import { UIInput, UISkeleton } from '@gohighlevel/ghl-ui'
import { ref, computed, onMounted, watch } from 'vue'
import { Select } from '../../Select'
import { LinkButton } from '../../LinkButton'
import {
  getLabel,
  useContactSearchForEmail,
} from '../../../composition/useContactSearchForEmail'
import { ccRecipient } from '../../../types'
import { EditEmailTemplateModal } from '../EditEmailTemplateModal'
import {
  useCustomNotificationSettings,
  useEmailTemplate,
} from '../../../composition'
import { EmailTemplatePreviewEvents } from '../../../types/components'
import { uniqBy } from 'lodash'
import { DocumentStatus } from '../../../enums'

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  recipients: {
    type: Array,
    default: () => [],
  },
  locationId: {
    type: String,
    default: '',
  },
  isDocumentSending: {
    type: Boolean,
    default: false,
  },
  documentName: {
    type: String,
    default: '',
  },
  ccRecipientsFromSettings: {
    type: Array,
    default: () => [],
  },
  ccRecipientsFromDocument: {
    type: Array,
    default: () => [],
  },
  bccRecipientsFromDocument: {
    type: Array,
    default: () => [],
  },
  notificationType: {
    type: String,
    default: '',
  },
  documentRevision: {
    type: Number,
    default: 1,
  },
  documentStatus: {
    type: String,
    default: '',
  },
  documentNotificationSettings: {
    type: Object,
    default: () => ({}),
  },
  documentExpiresOn: {
    type: String,
    default: '',
  },
})

const { fetchCustomNotificationSetting, notificationSetting } =
  useCustomNotificationSettings(props.locationId as string)
const {
  getEmailTemplates,
  cloneDefaultEmailTemplate,
  fetchEmailTemplates,
  emailTemplateList,
} = useEmailTemplate()
const emailTemplate = ref<any>({})
const emailTemplatePreviewLoading = ref(false)
const documentTitle = ref<string>(props?.documentName || '')
const documentName = computed(() => props.documentName)
const documentNotificationSettings = props.documentNotificationSettings || {}
const ccRecipientsFromDoc = computed(() => props.ccRecipientsFromDocument || [])
const bccRecipientsFromDoc = computed(
  () => props.bccRecipientsFromDocument || []
)
const emailCCOptions = ref<ccRecipient[]>([])
const emailBCCOptions = ref<ccRecipient[]>([])
const ccRecipientsFromSetting = computed(
  () => props.ccRecipientsFromSettings || []
)
const recipients = computed(() => props.recipients)
const initialSetup = SendDocumentsContractAction.EMAIL
const selectedInvoiceAction = ref(initialSetup)
const emailCc = ref<string[]>([])
const emailBcc = ref<string[]>([])
const showCc = ref<boolean>(false)
const showBcc = ref<boolean>(false)
const emailCCMap = ref<Map<string, ccRecipient>>(new Map())
const emailBCCMap = ref<Map<string, ccRecipient>>(new Map())
const ccContactSearch = useContactSearchForEmail(
  { enabled: showCc },
  props.locationId
)
const bccContactSearch = useContactSearchForEmail(
  { enabled: showBcc },
  props.locationId
)

watch(documentName, newDocumentName => {
  if (newDocumentName) {
    documentTitle.value = newDocumentName
  }
})

watch(documentNotificationSettings, newDocumentNotificationSettings => {
  if (newDocumentNotificationSettings) {
    documentNotificationSettings.value = newDocumentNotificationSettings
  }
})

watch(
  () => props.show,
  show => {
    if (show) {
      // if the document revision is greater than 1, always consider the cc recipient from the document.
      if (
        props.documentRevision > 1 ||
        (props.documentRevision === 1 &&
          DocumentStatus.DRAFT !== props.documentStatus)
      ) {
        if (ccRecipientsFromDoc.value.length > 0) {
          emailCc.value = ccRecipientsFromDoc.value.map(
            cc => `${cc.id}:${cc.email}`
          )
        }
      }

      // document revision should be 1 and document is in draft state. cc recipients should be considered from the global settings.
      if (
        DocumentStatus.DRAFT === props.documentStatus &&
        props.documentRevision === 1
      ) {
        if (ccRecipientsFromDoc.value.length > 0) {
          emailCc.value = ccRecipientsFromDoc.value.map(
            cc => `${cc.id}:${cc.email}`
          )
        } else if (ccRecipientsFromSetting.value.length > 0) {
          emailCc.value = props.ccRecipientsFromSettings.map(
            cc => `${cc.id}:${cc.email}`
          )
        }
        if (bccRecipientsFromDoc.value.length > 0) {
          emailBcc.value = bccRecipientsFromDoc.value.map(
            bcc => `${bcc.id}:${bcc.email}`
          )
        }
      }

      previewEmailTemplate()
    }
  }
)

const emits = defineEmits([
  ModalEvents.ON_SUCCESS,
  ModalEvents.ON_CANCEL,
  CCSendDocumentModalEvents.ON_UPDATE_DOCUMENT_TITLE,
  EmailTemplatePreviewEvents.EDIT_EMAIL_TEMPLATE_DOCUMENT_CONTRACT_FROM_PREVIEW,
])

const onCancelForms = () => {
  emits(ModalEvents.ON_CANCEL, { documentTitle: documentTitle.value })
}

const onSuccessForms = async () => {
  emits(ModalEvents.ON_SUCCESS, {
    documentTitle: documentTitle.value,
    emailTo: emailTo.value,
    emailCc: emailCc.value,
    emailBcc: emailBcc.value,
    medium: selectedInvoiceAction.value,
    ccRecipients: emailCc.value.map(cc => {
      const ccObj = emailCCMap.value.get(cc)
      delete ccObj?.label
      delete ccObj?.value
      return ccObj
    }),
    bccRecipients: emailBcc.value.map(bcc => {
      const bccObj = emailBCCMap.value.get(bcc)
      delete bccObj?.label
      delete bccObj?.value
      return bccObj
    }),
  })
}

const emailToOptions = computed(() => {
  const options = recipients.value.map(rec => ({
    label: getLabel(rec?.contactName || '', rec.email),
    value: `${rec?.id}:${rec?.email}`,
  }))
  return options
})

const emailTo = computed(() =>
  recipients.value.map(rec => `${rec?.id}:${rec?.email}`)
)

async function cloneTemplateAndOpenEmailBuilder() {
  const templateName =
    props.notificationType === ProposalsMailTemplates.SEND_PROPOSAL
      ? 'Document Sent'
      : 'Document Signed'

  const data = await cloneDefaultEmailTemplate({
    locationId: props.locationId,
    altType: 'location',
    notificationType: props.notificationType,
    templateName,
  })
  if (data && data.id) {
    return data.id
  }
  return null
}

async function previewEmailTemplate() {
  emailTemplatePreviewLoading.value = true
  await fetchCustomNotificationSetting()
  await fetchEmailTemplates(props.locationId)
  const emailTemplateId =
    props.documentNotificationSettings.receive?.templateId ||
    notificationSetting.value?.documentReceivedTemplateId

  const defaultEmailTemplateId =
    notificationSetting.value?.documentReceivedDefaultTemplateId
  let templateId =
    emailTemplateId && emailTemplateId != 'default'
      ? emailTemplateId
      : defaultEmailTemplateId
  const ifEmailTemplateExists = emailTemplateList.value.find(
    (template: any) => template.value === templateId
  )
  if (!templateId) {
    templateId = await cloneTemplateAndOpenEmailBuilder()
  } else if (!ifEmailTemplateExists) {
    templateId = await cloneTemplateAndOpenEmailBuilder()
  }
  if (templateId) {
    const templateData = await getEmailTemplates(props.locationId, templateId)
    emailTemplate.value = templateData
    if (!emailTemplate.value?._id) {
      await cloneTemplateAndOpenEmailBuilder()
    }
  }
  emailTemplatePreviewLoading.value = false
}

onMounted(() => {
  // if the document revision is greater than 1, always consider the cc recipient from the document.
  if (
    props.documentRevision > 1 ||
    (props.documentRevision === 1 &&
      DocumentStatus.DRAFT !== props.documentStatus)
  ) {
    if (ccRecipientsFromDoc.value && ccRecipientsFromDoc.value.length > 0) {
      onUpdateCcRecipients(
        ccRecipientsFromDoc.value.map(cc => `${cc.id}:${cc.email}`),
        ccRecipientsFromDoc.value
      )
      showCc.value = true
    }
  }

  // document revision should be 1 and document is in draft state. cc recipients should be considered from the global settings.
  if (
    DocumentStatus.DRAFT === props.documentStatus &&
    props.documentRevision === 1
  ) {
    if (ccRecipientsFromDoc.value && ccRecipientsFromDoc.value.length > 0) {
      onUpdateCcRecipients(
        ccRecipientsFromDoc.value.map(cc => `${cc.id}:${cc.email}`),
        ccRecipientsFromDoc.value
      )
      showCc.value = true
    } else if (props.ccRecipientsFromSettings.length > 0) {
      onUpdateCcRecipients(
        props.ccRecipientsFromSettings.map(cc => `${cc.id}:${cc.email}`),
        props.ccRecipientsFromSettings
      )
      showCc.value = true
    }

    if (bccRecipientsFromDoc.value && bccRecipientsFromDoc.value.length > 0) {
      onUpdateBccRecipients(
        bccRecipientsFromDoc.value.map(bcc => `${bcc.id}:${bcc.email}`),
        bccRecipientsFromDoc.value
      )
      showBcc.value = true
    }
  }
})

const onUpdateDocumentTitle = (value: string) => {
  emits(CCSendDocumentModalEvents.ON_UPDATE_DOCUMENT_TITLE, value)
}

const combinedCcContactOptions = computed(() => {
  const ccOptionsContacts = emailCCOptions.value.map(contact => ({
    ...contact,
    label: getLabel(contact?.contactName || '', contact.email),
    value: `${contact.id}:${contact.email}`,
  }))
  let emailContacts: any = []
  if (ccRecipientsFromDoc.value && ccRecipientsFromDoc.value.length > 0) {
    emailContacts = ccRecipientsFromDoc.value.map(contact => ({
      ...contact,
      label: getLabel(contact?.contactName || '', contact.email),
      value: `${contact.id}:${contact.email}`,
    }))
  } else if (props.ccRecipientsFromSettings.length > 0) {
    emailContacts = props.ccRecipientsFromSettings.map(contact => ({
      ...contact,
      label: getLabel(contact?.contactName || '', contact.email),
      value: `${contact.id}:${contact.email}`,
    }))
  }
  const combined = [
    ...ccOptionsContacts,
    ...emailContacts,
    ...ccContactSearch.contactList.value,
  ]
  return uniqBy(combined, 'value')
})

const combinesBccContactOptions = computed(() => {
  const bccOptionsContacts = emailBCCOptions.value.map(contact => ({
    ...contact,
    label: getLabel(contact?.contactName || '', contact.email),
    value: `${contact.id}:${contact.email}`,
  }))
  const emailContacts =
    Array.isArray(bccRecipientsFromDoc.value) &&
    bccRecipientsFromDoc.value.length > 0
      ? bccRecipientsFromDoc.value.map(contact => ({
          ...contact,
          label: getLabel(contact?.contactName || '', contact.email),
          value: `${contact.id}:${contact.email}`,
        }))
      : []

  const combined = [
    ...bccOptionsContacts,
    ...emailContacts,
    ...(bccContactSearch.contactList.value || []),
  ]
  return uniqBy(combined, 'value')
})

const onUpdateCcRecipients = (value: string[], options: any) => {
  emailCc.value = value
  value.map(cc => {
    const [id, email] = cc.split(':')
    const selected = options?.find(cc => cc.id === id && cc.email === email)
    if (selected) emailCCMap.value.set(cc, selected)
  })
  emailCCOptions.value = [...emailCCOptions.value, ...options]
}

const onUpdateBccRecipients = (value: string[], options: any) => {
  emailBcc.value = value
  value.map(cc => {
    const [id, email] = cc.split(':')
    const selected = options?.find(cc => cc.id === id && cc.email === email)
    if (selected) emailBCCMap.value.set(cc, selected)
  })
  emailBCCOptions.value = [...emailBCCOptions.value, ...options]
}

const openPreviewEmailTemplate = (params: {
  templateId: string
  locationId: string
  allowSavingNewTemplate: boolean
}) => {
  emits(
    EmailTemplatePreviewEvents.EDIT_EMAIL_TEMPLATE_DOCUMENT_CONTRACT_FROM_PREVIEW,
    {
      templateId: params.templateId,
      allowSavingNewTemplate: params.allowSavingNewTemplate,
    }
  )
}

const modalDisabled = computed(() => {
  return !documentTitle.value || props.isDocumentSending
})
</script>

<template>
  <CustomModal
    :width="620"
    :show="props.show"
    :modalType="'primary'"
    :title="$t('common.sendDocument')"
    @on-cancel="onCancelForms"
    @on-success="onSuccessForms"
    id="send-document-email-modal"
    :cancelActionText="$t('common.cancel')"
    :successActionText="$t('common.send')"
    :headerIcon="false"
    :disabled="modalDisabled"
  >
    <div class="mt-2 marker">
      <div class="text-sm text-gray-500">
        {{ $t('common.documentName') }}
      </div>
      <UIInput
        id="update-document-title"
        type="text"
        :show-count="true"
        :minlength="1"
        :placeholder="$t('common.documentName')"
        v-model="documentTitle"
        @update:model-value="onUpdateDocumentTitle"
        class="mt-2"
      />
      <span class="text-sm text-red-500" v-if="!documentTitle">{{
        $t('validations.required.documentName')
      }}</span>
    </div>
    <!-- <div class="flex space-x-3 text-sm mt-3">
      <UIRadio
        v-for="option in sendOptions"
        :key="option.id"
        id="sms-email-radio"
        :value="option.value"
        :checked="selectedInvoiceAction === option.value"
        @change="handleActionChange"
      >
        <span class="ml-1">
          {{ option.text }}
        </span>
      </UIRadio>
    </div> -->

    <div class="action-container py-3 space-y-3 mt-2 w-full">
      <div
        v-if="
          selectedInvoiceAction === SendDocumentsContractAction.SMS_AND_EMAIL ||
          selectedInvoiceAction === SendDocumentsContractAction.EMAIL
        "
      >
        <div class="flex border-none pt-2 pb-2 w-full items-stretch">
          <div class="w-11/12">
            <Select
              :label="$t('common.email')"
              id="document-email"
              type="avatar"
              tag
              :max-tag-count="1"
              :options="emailToOptions"
              :multiple="true"
              v-model:value="emailTo"
              :disableUpdate="true"
              :disable-create="true"
            />
          </div>
          <div class="w-1/12 pl-1">
            <LinkButton
              class="flex items-stretch h-full w-full"
              :is-active="showCc"
              @click="showCc = !showCc"
              :show-indicator="!showCc && emailCc.length > 0"
            >
              {{ $t('common.emailCc') }}
            </LinkButton>
          </div>
        </div>

        <Select
          v-if="showCc"
          :label="$t('common.emailCc')"
          id="CC"
          :options="combinedCcContactOptions"
          multiple
          tag
          class="cc-bcc border-none pt-2 pb-2 mt-3 w-full"
          :max-tag-count="1"
          type="avatar"
          validation-type="email"
          :loading="ccContactSearch.loading.value"
          :value="emailCc"
          @search="ccContactSearch.search"
          @clear-query="ccContactSearch.clearQuery"
          @scroll="ccContactSearch.fetchNextPage"
          :disabled="isDocumentSending"
          @update:value="onUpdateCcRecipients"
        />

        <Select
          v-if="showBcc"
          :label="$t('common.emailBcc')"
          id="BCC"
          :options="combinesBccContactOptions"
          multiple
          tag
          class="cc-bcc border-none pt-2 pb-2 mt-3 gap-2"
          type="avatar"
          validation-type="email"
          :max-tag-count="1"
          :loading="bccContactSearch.loading.value"
          :value="emailBcc"
          @search="bccContactSearch.search"
          @clear-query="bccContactSearch.clearQuery"
          @scroll="bccContactSearch.fetchNextPage"
          :disabled="isDocumentSending"
          @update:value="onUpdateBccRecipients"
        />

        <div>
          <div class="text-sm text-gray-500 mt-5">
            {{ $t('common.emailTemplate') }}
          </div>
          <UIInput
            id="email-template-name-input"
            readonly
            size="small"
            :value="emailTemplate?.name || ''"
            class="mt-2"
          />

          <EditEmailTemplateModal
            v-if="!emailTemplatePreviewLoading"
            :templateId="emailTemplate.id"
            :locationId="props.locationId"
            @edit-email-template-in-app="openPreviewEmailTemplate"
          />
          <UISkeleton
            v-else-if="emailTemplatePreviewLoading"
            text
            :repeat="2"
            class="w-1/2 mt-4"
          />
          <div
            v-if="documentExpiresOn"
            class="text-xs text-gray-400 mt-4 italic"
          >
            {{ $t('common.expirationSettingsHelperText') }}
            {{ documentExpiresOn }}
          </div>
        </div>
      </div>
    </div>
  </CustomModal>
</template>
