import {
  calculator,
  CurrencyDetails,
  CurrencyOption,
  DiscountCategory,
  DiscountType,
  ELEMENTS_LOOKUP,
  LineItem,
  PriceTypeEnum,
  ScheduleRRuleOptions,
} from '@gohighlevel/ghl-proposals-common'

import { useLineItems } from '@/composition'
import i18n from '@gohighlevel/ghl-proposals-common/src/locales'
import {
  IElement,
  PaymentSchedule,
  PaymentScheduleType,
} from '@gohighlevel/ghl-proposals-common/src/types'
import { defineStore } from 'pinia'
import { useAppStore } from './app'
import { useBuilderStore } from './builder'

interface ScheduleValidation {
  errors: Record<string, any>[]
  results: Record<string, any>[]
  valid: boolean
}
interface ProductListStore {
  selectedRows: string[]
  activeRow: LineItem | null
  currency: string
  currencyDetails: CurrencyDetails
  currencyOptions: CurrencyOption[]
  activeListId: string | null
  schedule: ScheduleRRuleOptions
  invoiceType: PriceTypeEnum
  scheduleValidation: ScheduleValidation
  invoiceId: string
  invoiceScheduleId: string
  generateInvoiceOnSigning: boolean
  paymentDepositSchedule: PaymentSchedule
  enableAutoPayment: boolean
}
export const useProductListStore = defineStore('productListStore', {
  state: (): ProductListStore => ({
    activeListId: null,
    selectedRows: [],
    activeRow: null,
    currency: '',
    currencyDetails: {} as CurrencyDetails,
    currencyOptions: [],
    schedule: {} as ScheduleRRuleOptions, // schedule for the recurring invoice
    invoiceType: PriceTypeEnum.ONETIME,
    scheduleValidation: {} as ScheduleValidation,
    invoiceId: '',
    invoiceScheduleId: '',
    generateInvoiceOnSigning: true,
    paymentDepositSchedule: {} as PaymentSchedule, // schedule for the deposit payment [ applicable for one time payment]
    enableAutoPayment: false,
  }),
  getters: {
    getActiveRow: state => state.activeRow,
  },
  actions: {
    setSelectedRows(rows: string[]) {
      this.selectedRows = rows
    },
    setActiveRow(
      lineItem: LineItem | null | undefined,
      tableId: string | null
    ) {
      if (lineItem) {
        this.activeRow = Object.assign({}, lineItem)
      } else {
        this.activeRow = Object.assign({}, null)
      }
      this.activeListId = tableId
    },
    updateActiveRow(options: {
      key: keyof LineItem
      value: string | number | undefined
    }) {
      if (this.activeRow) {
        this.activeRow = { ...this.activeRow, ...options }
      }
    },
    setProductListCurrency(currency: string) {
      this.currency = currency
    },
    setCurrencyDetails(currencyData: CurrencyDetails) {
      this.currencyDetails = currencyData
      this.currencyOptions = Object.entries(currencyData.currency).map(
        ([currencyCode, currencyData]: any) => {
          return { label: currencyData.code, value: currencyCode }
        }
      )
    },
    updateSchedule(values: ScheduleRRuleOptions) {
      const appStore = useAppStore()
      appStore.setUnsavedChanges(true)
      this.schedule = values
    },
    setSchedule(rrule: ScheduleRRuleOptions) {
      this.schedule = rrule
    },
    setPaymentDepositSchedule(schedule: PaymentSchedule) {
      const appStore = useAppStore()
      this.paymentDepositSchedule = schedule
      // if schedule is not empty, then make all the line items optional & qtyEditable as false
      if (schedule && Object.keys(schedule).length > 0) {
        const builderStore = useBuilderStore()
        builderStore.setAllLineItemsAndQtyOptional(false)
        // we will relay on the user input, so we will not enable auto payment on change of invoice type
        // this.enableAutoPaymentOnChangeInvoiceType()
      }
      appStore.setUnsavedChanges(true)
    },
    removePaymentDepositSchedule() {
      const appStore = useAppStore()
      this.paymentDepositSchedule = {} as PaymentSchedule
      appStore.setUnsavedChanges(true)
    },
    setInvoiceType(invoiceType: PriceTypeEnum) {
      const appStore = useAppStore()
      this.invoiceType = invoiceType
      // we will relay on the user input, so we will not enable auto payment on change of invoice type
      // this.enableAutoPaymentOnChangeInvoiceType()
      if (invoiceType === PriceTypeEnum.ONETIME) {
        if (
          this.paymentDepositSchedule &&
          Object.keys(this.paymentDepositSchedule).length > 0
        ) {
          const builderStore = useBuilderStore()
          builderStore.setAllLineItemsAndQtyOptional(false)
        }
      }
      appStore.setUnsavedChanges(true)
    },
    updateScheduleErrors(validation: ScheduleValidation) {
      this.scheduleValidation = validation
    },
    setInvoiceId(invoiceId: string) {
      this.invoiceId = invoiceId
    },
    setScheduleId(scheduleId: string) {
      this.invoiceScheduleId = scheduleId
    },
    getProductListErrors() {
      const errors: string[] = []
      const builderStore = useBuilderStore()
      const productLists = builderStore.getAllProductList()
      const subTotal = this.getSubTotalForProductList()
      const totalDiscount = this.getDiscountValue()

      if (productLists && productLists.length > 0) {
        if (this.invoiceType === PriceTypeEnum.RECURRING) {
          const scheduleKeys = Object.keys(this.schedule)
          if (scheduleKeys.length === 0) {
            errors.push(i18n.global.t('validations.required.schedule'))
          }
          if (
            scheduleKeys.length > 0 &&
            this.scheduleValidation &&
            !this.scheduleValidation?.valid &&
            this.scheduleValidation?.errors
          ) {
            const result = Object.values(this.scheduleValidation?.errors)
            errors.push(...result.map(error => String(error)))
          }

          // total discount should not be greater than the total amount of all recurring items
          const subTotalForRecurringLineItems =
            this.getTotalAmountForAllRecurringItems()

          if (subTotalForRecurringLineItems < totalDiscount) {
            errors.push(
              i18n.global.t(
                'validations.invalid.discountValueGreaterThanTotalRecurringAmount'
              )
            )
          }
        }

        if (this.invoiceType === PriceTypeEnum.ONETIME) {
          const depositScheduleKeys = Object.keys(this.paymentDepositSchedule)
          if (this.paymentDepositSchedule && depositScheduleKeys.length > 0) {
            if (
              this.paymentDepositSchedule.type === PaymentScheduleType.FIXED
            ) {
              const totalScheduleValue = Number(
                this.paymentDepositSchedule.schedules
                  .reduce((sum, item) => sum + item.value, 0)
                  ?.toFixed(2)
              )
              const grandTotal = this.getGrandTotalForProductList()
              if (
                grandTotal != totalScheduleValue ||
                totalScheduleValue === 0 ||
                grandTotal === 0
              ) {
                errors.push(
                  i18n.global.t(
                    'validations.invalid.paymentDepositScheduleTotalValue'
                  )
                )
              }
            }
          }

          if (subTotal < totalDiscount) {
            errors.push(
              i18n.global.t(
                'validations.invalid.discountValueGreaterThanTotalAmount'
              )
            )
          }
        }

        // check the quantity of the each line items, and its must be greater than 0
        // and should be a number and if it has decimal then its should have 2 decimal places.
        const lineItems = productLists.reduce((acc, elem) => {
          acc.push(...elem.component.options.lineItems)
          return acc
        }, [] as LineItem[])
        lineItems.forEach(item => {
          const { name, qty } = item
          if (typeof qty !== 'number' || qty <= 0) {
            errors.push(
              `${name}: ` +
                i18n.global.t('validations.invalid.invoiceQuantityNumber')
            )
            return
          }
          if (!/^\d+(\.\d{1,2})?$/.test(qty.toString())) {
            errors.push(
              `${name}: ` +
                i18n.global.t('validations.invalid.invoiceQuantityDecimal')
            )
          }
        })
      }
      return errors
    },
    updateInvoiceTypeIfRecurringProductExist() {
      const builderStore = useBuilderStore()
      const recurringProduct = builderStore.isRecurringProduct()
      if (recurringProduct) {
        this.setInvoiceType(PriceTypeEnum.RECURRING)
        if (
          this.schedule?.intervalType === undefined &&
          this.schedule?.interval === undefined
        ) {
          this.updateSchedule({
            intervalType: recurringProduct?.recurring?.interval,
            interval: recurringProduct?.recurring?.intervalCount,
          } as ScheduleRRuleOptions)
        }
      } else {
        this.setInvoiceType(PriceTypeEnum.ONETIME)
      }
    },
    resetSchedule() {
      this.schedule = {} as ScheduleRRuleOptions
      this.invoiceType = PriceTypeEnum.ONETIME
    },
    toggleGenerateInvoiceOnSigning(value: boolean) {
      this.generateInvoiceOnSigning = value
    },
    toggleEnableAutoPayment(value: boolean) {
      const appStore = useAppStore()
      this.enableAutoPayment = value
      appStore.setUnsavedChanges(true)
    },
    getGrandTotalForProductList() {
      const builderStore = useBuilderStore()
      return Number(
        builderStore.pages
          .reduce((acc, page) => {
            page.children?.forEach(child => {
              if (child.type === ELEMENTS_LOOKUP.PRODUCT_LIST) {
                const { amountDue } = useLineItems(child.id)
                acc += amountDue.value
              }
              if (child.type === ELEMENTS_LOOKUP.ROW) {
                child?.children?.forEach(column => {
                  column?.children?.forEach(child => {
                    if (child.type === ELEMENTS_LOOKUP.PRODUCT_LIST) {
                      const { amountDue } = useLineItems(child.id)
                      acc += amountDue.value
                    }
                  })
                })
              }
            })
            return acc
          }, 0)
          ?.toFixed(2)
      )
    },
    getTotalAmountForAllRecurringItems() {
      const builderStore = useBuilderStore()
      return Number(
        builderStore.pages
          .reduce((acc, page) => {
            page.children?.forEach(child => {
              if (child.type === ELEMENTS_LOOKUP.PRODUCT_LIST) {
                const { subTotalRecurringLineItems } = useLineItems(child.id)
                acc += subTotalRecurringLineItems.value
              }
              if (child.type === ELEMENTS_LOOKUP.ROW) {
                child?.children?.forEach(column => {
                  column?.children?.forEach(child => {
                    if (child.type === ELEMENTS_LOOKUP.PRODUCT_LIST) {
                      const { subTotalRecurringLineItems } = useLineItems(
                        child.id
                      )
                      acc += subTotalRecurringLineItems.value
                    }
                  })
                })
              }
            })
            return acc
          }, 0)
          ?.toFixed(2)
      )
    },
    getSubTotalForProductList() {
      const builderStore = useBuilderStore()
      return Number(
        builderStore.pages
          .reduce((acc, page) => {
            page.children?.forEach(child => {
              if (child.type === ELEMENTS_LOOKUP.PRODUCT_LIST) {
                const { subTotal } = useLineItems(child.id)
                acc += subTotal.value
              }
              if (child.type === ELEMENTS_LOOKUP.ROW) {
                child?.children?.forEach(column => {
                  column?.children?.forEach(child => {
                    if (child.type === ELEMENTS_LOOKUP.PRODUCT_LIST) {
                      const { subTotal } = useLineItems(child.id)
                      acc += subTotal.value
                    }
                  })
                })
              }
            })
            return acc
          }, 0)
          ?.toFixed(2)
      )
    },
    enableAutoPaymentOnChangeInvoiceType() {
      if (
        this.invoiceType === PriceTypeEnum.ONETIME &&
        Object.keys(this.paymentDepositSchedule).length > 0
      ) {
        this.toggleEnableAutoPayment(true)
      } else if (this.invoiceType === PriceTypeEnum.RECURRING) {
        this.toggleEnableAutoPayment(true)
      }
    },
    getEachDiscountsFromProductList() {
      const builderStore = useBuilderStore()
      return builderStore.pages.reduce((acc, page: IElement) => {
        page.children?.forEach(children => {
          if (
            children.type === ELEMENTS_LOOKUP.PRODUCT_LIST &&
            children.component.options.lineItems &&
            children.component.options.lineItems.length > 0 &&
            children.component.options.discountValue > 0
          ) {
            acc.push({
              id: children.id,
              value: children.component.options.discountValue,
              type: children.component.options.discountType,
            })
          }
          if (children.type === ELEMENTS_LOOKUP.ROW) {
            children?.children?.forEach(column => {
              column?.children?.forEach(child => {
                if (
                  child.type === ELEMENTS_LOOKUP.PRODUCT_LIST &&
                  child.component.options.lineItems &&
                  child.component.options.lineItems.length > 0 &&
                  child.component.options.discountValue > 0
                ) {
                  acc.push({
                    id: child.id,
                    value: child.component.options.discountValue,
                    type: child.component.options.discountType,
                  })
                }
              })
            })
          }
        })
        return acc
      }, [] as Record<string, any>[])
    },
    getTotalDiscountInPercentage() {
      const builderStore = useBuilderStore()
      // check how many product list has for the document.
      // This is added because of the miscalculation while getting the percentage
      // if it has only one product list and the discount type is in percentage take that value.
      const productLists = builderStore.getAllProductList()
      if (productLists && productLists.length === 1) {
        if (
          productLists[0].component?.options?.discountType ===
          DiscountType.PERCENTAGE
        ) {
          return productLists[0].component?.options?.discountValue
        }
      }

      // otherwise go ahead with the reduce operation.
      let finalProductListVersion = 1
      const totalDiscountAcrossTables = productLists.reduce(
        (acc, productList) => {
          const { totalDiscount, productListVersion } = useLineItems(
            productList.id
          )
          finalProductListVersion = productListVersion.value
          acc += totalDiscount.value
          return acc
        },
        0
      )
      const getSubTotalForProductList = this.getSubTotalForProductList()
      const { getPercentage } = calculator(
        getSubTotalForProductList,
        finalProductListVersion
      )
      return getPercentage(totalDiscountAcrossTables, finalProductListVersion)
    },
    getDiscountValueForProductList() {
      const discounts = this.getEachDiscountsFromProductList()
      let discountValue = this.getTotalDiscountInPercentage()
      let discountType = DiscountCategory.PERCENTAGE
      if (discounts.every(({ type }) => type === DiscountType.CUSTOM_AMOUNT)) {
        discountType = DiscountCategory.FIXED
        discountValue = discounts.reduce((acc, { value }) => {
          acc = acc + (value as number)
          return acc
        }, 0)
      }
      return { discountValue, discountType }
    },
    getDiscountValue() {
      const { discountValue, discountType } =
        this.getDiscountValueForProductList()
      const totalAmount = this.getGrandTotalForProductList()
      let discount = 0
      if (discountType === DiscountCategory.PERCENTAGE) {
        discount = (totalAmount * discountValue) / 100
      } else if (discountType === DiscountCategory.FIXED) {
        discount = discountValue
      }
      return discount
    },
  },
})
