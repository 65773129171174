<script setup lang="ts">
import { useActionBarStore } from '@/store/actionBar'
import { useBuilderStore } from '@/store/builder'
import { UIDrawer, UIDrawerContent } from '@gohighlevel/ghl-ui'
import { ref } from 'vue'

import AppendedFileUploadModal from '../common/AppendedFileUploadModal.vue'
import {
  DraggablePagesForDrawer,
  IPage,
} from '@gohighlevel/ghl-proposals-common'
import { useAppStore } from '@/store/app'
import { useRoute } from 'vue-router'

const showUploadModal = ref(false)
const route = useRoute()
const appendImagesFromIndex = ref(0)
const store = useBuilderStore()
const storeAction = useActionBarStore()
const appStore = useAppStore()
function onClose() {
  storeAction.togglePages()
}

function scrollToPage(page: IPage) {
  const el = document.getElementById(`page-${page.id}`)
  const editorArea = document.querySelector('.page-loop')
  if (el && editorArea) {
    const elementOffsetTop = el.offsetTop
    const topBarHeight = 110
    editorArea.scrollTo({
      top: elementOffsetTop - topBarHeight,
      behavior: 'smooth',
    })
  }
}

function reorderPage(pages: IPage[]) {
  store.setPages(pages)
  appStore.setUnsavedChanges(true)
}
function openUploadModal(uploadIndex: number) {
  showUploadModal.value = true
  appendImagesFromIndex.value = uploadIndex
}

const toggleModal = () => {
  showUploadModal.value = !showUploadModal.value
}
</script>

<template>
  <!-- AppendedFileUploadModal -->
  <AppendedFileUploadModal
    v-if="showUploadModal"
    :show="showUploadModal"
    @close-modal="toggleModal"
    :isAppend="true"
    :appendImagesFromIndex="appendImagesFromIndex"
    :documentId="
      Array.isArray(route.params.documentId)
        ? route.params.documentId[0]
        : route.params.documentId
    "
  />
  <UIDrawer
    :width="340"
    id="elements-drawer"
    :show="storeAction.pagesActive"
    @update:show="onClose"
    :placement="'left'"
    to="#drawer-target"
    class="!shadow-page h-screen"
    :trap-focus="false"
  >
    <UIDrawerContent id="pages-drawer-content" :title="$t('common.pages')">
      <DraggablePagesForDrawer
        :draggable="store.isDocumentEditable"
        :pages="store.pages"
        :allowAddPages="store.isDocumentEditable"
        @onPageClick="scrollToPage"
        @on-page-reordered="reorderPage"
        @uploadModalTriggered="openUploadModal"
      />
    </UIDrawerContent>
  </UIDrawer>
</template>
