import {
  BuilderAppStore,
  CustomDocumentFields,
  IDocument,
  RedirectSettings,
  TimeZoneEnum,
  ccRecipient,
  isValidUrl,
  validateEmail,
} from '@gohighlevel/ghl-proposals-common'
import { isEmpty, isNil } from 'lodash'

import { IProposalCustomerCustomNotificationSettings } from '@gohighlevel/ghl-proposals-common/src/services/CustomNotificationService'
import { defineStore } from 'pinia'
import { toRaw } from 'vue'

export const useAppStore = defineStore('appStore', {
  state: (): BuilderAppStore => ({
    email: '',
    userId: '',
    apiKey: '',
    tokenId: '',
    companyId: '',
    timeZone: '' as TimeZoneEnum,
    userName: '',
    parentBaseUrl: '',
    handshake: {} as Promise<any>,
    requestRetryCount: 0,
    document: {
      enableSigningOrder: false,
      enableDirectPayment: false,
      enableAutoSendInvoice: false,
      paymentLiveMode: true,
      notificationSettings: {},
    } as IDocument,
    unsavedChanges: false,
    sendAttempted: false,
    currency: 'USD',
    locationStaticCustomValues: [],
    locationCustomValues: [],
    locationCustomFields: [],
    userData: {} as Promise<any>,
    linkReference: [],
    whiteLabelViewerBaseUrl: '',
    locale: 'en-US',
    isInternal: false,
    invoiceWhiteLabelBaseUrl: '',
    globalDocumentSettings: {} as IProposalCustomerCustomNotificationSettings,
  }),
  getters: {
    primaryContact: state => {
      return state.document?.recipients?.find(element => {
        return element.isPrimary === true
      })
    },
    flatDocumentVariables: state => {
      return state.document.variables.reduce((acc, variable) => {
        return {
          ...acc,
          [variable.fieldKey]: variable.value,
        }
      })
    },
  },
  actions: {
    updateDocumentVariable({ fieldKey, value, type }: any) {
      const index = this.document.variables.findIndex(
        variable => variable.fieldKey === fieldKey
      ) as number
      if (index === -1) {
        this.document.variables.push({ fieldKey, value, type })
      } else {
        this.document.variables[index].value = value
      }
      this.setUnsavedChanges(true)
    },
    deleteDocumentCustomVariable(value: string) {
      this.document.variables = [
        ...this.document.variables.filter(({ fieldKey }) => fieldKey !== value),
      ]
      this.setUnsavedChanges(true)
    },
    setDocument(document: IDocument) {
      this.document = {
        ...(document ? document : {}),
        // for backward compatibility
        ...(document?.recipients &&
        document?.recipients?.every(({ signingOrder }) => signingOrder === 1)
          ? {
              recipients: document?.recipients?.map((recipient, index) => {
                return {
                  ...recipient,
                  signingOrder: index + 1,
                }
              }),
            }
          : {}),
      } as IDocument
    },
    setLinkReference(links: any) {
      this.linkReference = links
    },
    setWhiteLabelViewerBaseUrl(baseUrl: string) {
      this.whiteLabelViewerBaseUrl = baseUrl
    },
    setInvoiceWhiteLabelBaseUrl(baseUrl: string) {
      this.invoiceWhiteLabelBaseUrl = baseUrl
    },
    setGlobalDocumentSettings(
      settings: IProposalCustomerCustomNotificationSettings
    ) {
      this.globalDocumentSettings = settings
    },
    setRedirectSettings(settings: RedirectSettings) {
      this.document.redirectSettings = settings
      this.setUnsavedChanges(true)
    },
    setUnsavedChanges(changes: boolean) {
      this.unsavedChanges = changes
    },
    setCCAndBccRecipients(cc: ccRecipient[]) {
      this.document.ccRecipients = cc
      this.setUnsavedChanges(true)
    },
    setExpiryStatuses(
      expiresOn: string,
      expiryDays: number,
      isExpiryEnabled: boolean,
      isExpiryReminderEnabled: boolean
    ) {
      this.document.expiresOn = expiresOn
      this.document.expiryDays = expiryDays
      this.document.isExpiryEnabled = isExpiryEnabled
      this.document.isExpiryReminderEnabled = isExpiryReminderEnabled
      this.setUnsavedChanges(true)
    },
    setupApp(params: BuilderAppStore) {
      this.email = params.email
      this.userId = params.userId
      this.apiKey = params.apiKey
      this.tokenId = params.tokenId
      this.companyId = params.companyId
      this.timeZone = params.timeZone
      this.userName = params.userName
      this.parentBaseUrl = params.parentBaseUrl
      this.handshake = params.handshake
      this.currency = params.currency

      this.isInternal = [
        '5DP4iH6HLkQsiKESj6rh',
        'YuTUZlUtrwBtvmgByZDW',
        'ktapXmiRvMM4pJHyN3b3',
      ].includes(params.companyId) // 5DP4iH6HLkQsiKESj6rh (staging) & YuTUZlUtrwBtvmgByZDW (production) & ktapXmiRvMM4pJHyN3b3(production)
    },

    updateLoggedInUserData(userData: any) {
      this.userData = userData
    },
    updateRequestRetryCount(count: number) {
      this.requestRetryCount = count
    },
    updateDocumentName(name: string) {
      this.document.name = name
      this.setUnsavedChanges(true)
    },
    refreshToken(token: string) {
      this.tokenId = token
      console.info('refreshToken')
    },
    updateDocumentOption(
      key: keyof CustomDocumentFields,
      value: CustomDocumentFields[keyof CustomDocumentFields]
    ) {
      this.document[key] = value
      this.setUnsavedChanges(true)
    },
    updateLocale(locale: string) {
      this.locale = locale
    },
    toggleSigningOrder(value: boolean) {
      this.document.enableSigningOrder = value
      this.setUnsavedChanges(true)
    },
    toggleDirectPayment(value: boolean) {
      this.document.enableDirectPayment = value
      if (value === true) {
        this.document.enableAutoSendInvoice = true
      }
      this.setUnsavedChanges(true)
    },
    toggleAutoSendInvoice(value: boolean) {
      this.document.enableAutoSendInvoice = value
      this.setUnsavedChanges(true)
    },
    togglePaymentLiveMode(value: boolean) {
      this.document.paymentLiveMode = value
      this.setUnsavedChanges(true)
    },
    updateDocumentNotificationSenderSettings(
      value:
        | {
            sender?: { fromEmail?: string; fromName?: string }
          }
        | undefined
    ) {
      if (!this.document.notificationSettings)
        this.document.notificationSettings = {}

      delete this.document.notificationSettings['value']
      if (isEmpty(value) || isEmpty(value?.sender)) {
        this.document.notificationSettings.sender = {}
        this.setUnsavedChanges(true)
        return
      }

      if (isNil(this.document.notificationSettings.sender)) {
        this.document.notificationSettings.sender = {}
      }

      if (!isNil(value?.sender?.fromName)) {
        this.document.notificationSettings.sender.fromName =
          value.sender.fromName
      }

      if (!isNil(value?.sender?.fromEmail)) {
        this.document.notificationSettings.sender.fromEmail =
          value.sender.fromEmail
      }

      this.setUnsavedChanges(true)
    },
    updateDocumentNotificationReceiveSettings(
      value:
        | {
            receive?: { subject?: string; templateId?: string }
          }
        | undefined
    ) {
      if (!this.document.notificationSettings)
        this.document.notificationSettings = {}

      if (!this.document.notificationSettings.receive) {
        this.document.notificationSettings.receive = {}
      }

      if (!isNil(value?.receive?.subject)) {
        this.document.notificationSettings.receive.subject =
          value.receive.subject
      }

      if (!isNil(value?.receive?.templateId)) {
        this.document.notificationSettings.receive.templateId =
          value.receive.templateId
      }

      this.setUnsavedChanges(true)
    },
    getSettingsErrors() {
      const errors: string[] = []
      const rawDocument = toRaw(this.document)
      if (
        !rawDocument.notificationSettings ||
        isEmpty(rawDocument.notificationSettings)
      )
        return []

      /**
       * Nested insert is introduced with _id property which needs to be removed to check if settings object is empty.
       * This is introduced if the user enables settings and disables it again
       */
      delete rawDocument.notificationSettings['_id']

      if (
        !isEmpty(rawDocument.notificationSettings) &&
        !isEmpty(rawDocument.notificationSettings.sender) &&
        !isNil(rawDocument.notificationSettings.sender?.fromName) &&
        !rawDocument.notificationSettings.sender?.fromName
      ) {
        errors.push('Sender Name is required')
      }

      const specialCharRegex = /[^a-zA-Z0-9\s]/
      if (
        !isEmpty(rawDocument.notificationSettings) &&
        rawDocument.notificationSettings.sender?.fromName
      ) {
        if (
          specialCharRegex.test(
            rawDocument.notificationSettings.sender?.fromName
          )
        ) {
          errors.push('Name format is incorrect')
        }
      }

      if (
        !isEmpty(rawDocument.notificationSettings) &&
        !isEmpty(rawDocument.notificationSettings.sender) &&
        !isNil(rawDocument.notificationSettings.sender?.fromEmail) &&
        !rawDocument.notificationSettings.sender?.fromEmail
      ) {
        errors.push('Email is required')
      }
      if (
        !isEmpty(rawDocument.notificationSettings) &&
        rawDocument.notificationSettings.sender?.fromEmail &&
        !validateEmail(rawDocument.notificationSettings.sender?.fromEmail)
      ) {
        errors.push('Invalid Email Format')
      }

      if (
        !isEmpty(rawDocument.notificationSettings) &&
        !isNil(rawDocument.notificationSettings.receive?.subject) &&
        isEmpty(rawDocument.notificationSettings.receive?.subject)
      ) {
        errors.push('Subject is required')
      }

      // if (
      //   !isEmpty(rawDocument.notificationSettings) &&
      //   !rawDocument.notificationSettings.receive?.templateId
      // ) {
      //   errors.push('Template ID is required')
      // }
      return errors
    },
    getRedirectUrlSettingErrors() {
      const errors: string[] = []
      if (
        this.document?.redirectSettings &&
        this.document?.redirectSettings?.enableDocumentRedirectUrl
      ) {
        if (!this.document?.redirectSettings?.documentRedirectUrl) {
          errors.push('Document Redirect URL is required')
        }
        if (
          this.document?.redirectSettings?.documentRedirectUrl &&
          !isValidUrl(this.document?.redirectSettings?.documentRedirectUrl)
        ) {
          errors.push('Invalid URL format')
        }
      }
      return errors
    },
  },
})
