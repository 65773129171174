<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import {
  UIButton,
  UIDropdown,
  renderIcon,
  useNotification,
} from '@gohighlevel/ghl-ui'
import {
  DotsVerticalIcon,
  Pencil01Icon,
  FileDownload02Icon,
  Trash01Icon,
  CheckDone01Icon,
  ReceiptIcon,
  Save01Icon,
  CalculatorIcon,
  ClipboardXIcon,
} from '@gohighlevel/ghl-icons/24/outline'
import {
  DocumentStatus,
  PaymentStatus,
  PriceTypeEnum,
} from '@gohighlevel/ghl-proposals-common'
import { useDocument, usePostmate } from '@/composition'
import { useBuilderStore } from '@/store/builder'
import { computed, ref } from 'vue'
import CustomModal from '@/components/common/CustomModal.vue'
import { useRoute, useRouter } from 'vue-router'
import { useAppStore } from '@/store/app'
import DocumentValidationModal from '@/components/modals/DocumentValidationModal.vue'
import { useProductListStore } from '@/store/productListStore'
import AddExpiryModal from '@/components/modals/AddExpiryModal.vue'

const { t } = useI18n()
const router = useRouter()
const route = useRoute()
const notification = useNotification()
const showActionConfirmationModal = ref<boolean>(false)
const activeAction = ref<string | null | DocumentStatus>(null)
const actionModalTitle = ref('')
const actionModalDescription = ref('')
const showErrorModal = ref(false)
const showAddExpiryModal = ref(false)

const toggleModal = () => {
  showErrorModal.value = !showErrorModal.value
}

const openActionModal = () => {
  showActionConfirmationModal.value = true
}

const closeActionModal = () => {
  showActionConfirmationModal.value = false
  activeAction.value = null
  actionModalTitle.value = ''
  actionModalDescription.value = ''
}

const openAddExpiryModal = () => {
  showAddExpiryModal.value = true
}

const toggleAddExpiryModal = () => {
  showAddExpiryModal.value = !showAddExpiryModal.value
}

const {
  convertToDraft,
  loading,
  markAsCompleted,
  downloadPdf,
  pushToListPage,
  removeDocument,
  saveDocument,
  saveAsTemplate,
  isDocumentValid,
} = useDocument()

const builderStore = useBuilderStore()
const appStore = useAppStore()
const productListStore = useProductListStore()
const invoices = computed(() => appStore.document.invoices)

const invoicesType = computed(() => productListStore.invoiceType)
const invoiceId = computed(() => {
  if (!productListStore?.invoiceId && !productListStore?.invoiceScheduleId) {
    return null
  }
  if (invoicesType.value && invoicesType.value === PriceTypeEnum.ONETIME) {
    if (productListStore?.invoiceId) {
      return productListStore?.invoiceId
    }
  }
  if (invoicesType.value && invoicesType.value === PriceTypeEnum.RECURRING) {
    if (productListStore?.invoiceScheduleId) {
      return productListStore?.invoiceScheduleId
    }
  }

  return null
})

const { redirectOnParentRoute } = usePostmate()

const optionsList = computed(() => {
  const status = builderStore.status
  const options = [
    {
      label: t('common.moveToDraft'),
      key: DocumentStatus.DRAFT,
      icon: renderIcon(Pencil01Icon),
    },
    {
      label: t('common.markAsCompleted'),
      key: DocumentStatus.COMPLETED,
      icon: renderIcon(CheckDone01Icon),
    },
    {
      label: t('common.downloadPDF'),
      key: 'download',
      icon: renderIcon(FileDownload02Icon),
    },
    {
      label: t('common.convertToTemplate'),
      key: 'save_as_template',
      icon: renderIcon(Save01Icon),
    },
    {
      type: 'divider',
      key: 'd1',
    },
    {
      label: t('common.delete'),
      key: 'delete',
      icon: renderIcon(Trash01Icon),
    },
  ]
  if (status === DocumentStatus.DRAFT) {
    const addExpiryOption = {
      label: t('common.addExpiry'),
      key: 'add_expiry',
      icon: renderIcon(ClipboardXIcon),
    }
    const dividerIndex = options.findIndex(option => option.key === 'd1')
    options.splice(dividerIndex, 0, addExpiryOption)
    return options.filter(
      ({ key }) => key !== DocumentStatus.DRAFT || key === 'versions'
    )
  }
  if (
    status === DocumentStatus.ACCEPTED ||
    status === DocumentStatus.COMPLETED
  ) {
    const isRecurring =
      invoicesType.value && invoicesType.value === PriceTypeEnum.RECURRING
        ? true
        : false

    const isDisabled =
      invoiceId.value || (invoices.value && invoices.value?.length > 0)
        ? false
        : true

    options.push({
      label: isRecurring
        ? t('common.viewScheduleInvoice')
        : t('common.viewInvoice'),
      key: 'view_invoice',
      icon: isRecurring ? renderIcon(CalculatorIcon) : renderIcon(ReceiptIcon),
      disabled: isDisabled,
    })
  }
  if (
    status === DocumentStatus.SENT ||
    status === DocumentStatus.COMPLETED ||
    status === DocumentStatus.VIEWED
  ) {
    const opt = options.filter(({ key }) => key !== 'delete' && key !== 'd1')
    if (status === DocumentStatus.COMPLETED) {
      return opt.filter(({ key }) => key !== DocumentStatus.COMPLETED)
    } else {
      return opt
    }
  }

  return options
})

const downloadProposalPdf = async () => {
  const data = await downloadPdf()
  if (data && data.isLargeFile) {
    notification['info']({
      title: 'Info',
      description: data?.message,
      duration: 10000,
    })
  }

  if (data?.url) {
    const { url } = data
    const anchor = document.createElement('a')
    anchor.setAttribute('href', url)
    anchor.setAttribute('download', `${appStore.document.name}.pdf`)
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url)
        removeEventListener('click', clickHandler)
      }, 150)
    }
    anchor.addEventListener('click', clickHandler, false)
    anchor.click()
  }
}

const onSelect = async (value: string | DocumentStatus) => {
  if (value === 'delete') {
    actionModalTitle.value = t('validations.warnings.deleteDocumentAction')
    actionModalDescription.value = t('validations.warnings.deleteDocument')
    activeAction.value = 'delete'
    openActionModal()
  }
  if (value === DocumentStatus.COMPLETED) {
    if (builderStore.hasLineItems && !isDocumentValid()) {
      toggleModal()
    } else {
      actionModalTitle.value = t('validations.warnings.markAsCompleted')
      actionModalDescription.value = t('validations.warnings.actionUndone')
      activeAction.value = DocumentStatus.COMPLETED
      openActionModal()
    }
  }
  if (value === DocumentStatus.DRAFT) {
    actionModalTitle.value = t('validations.warnings.moveToDraft')
    actionModalDescription.value = t(
      'validations.warnings.clearSignatureAndRecipient'
    )
    activeAction.value = DocumentStatus.DRAFT
    openActionModal()
  }
  if (value === 'versions') {
    router.push({
      name: 'versions',
      path: '/v2/location/:locationId/payments/proposals-estimates/edit/:documentId/versions',
      params: {
        locationId: route.params.locationId as string,
        documentId: route.params.documentId as string,
      },
    })
  }
  if (value === 'download') {
    await saveDocument()
    await downloadProposalPdf()
  }
  if (value === 'view_invoice') {
    if (invoicesType.value === PriceTypeEnum.ONETIME && invoiceId.value) {
      if (appStore.document.paymentStatus === PaymentStatus.PAID) {
        window.open(
          `${appStore.invoiceWhiteLabelBaseUrl}/invoice/${invoiceId.value}`,
          '_blank'
        )
      } else {
        redirectOnParentRoute({
          name: 'payment-products-invoice-editor',
          params: {
            version: 'v2',
            location_id: route.params.locationId as string,
            invoiceId: invoiceId.value as string,
          },
        })
      }
    } else if (
      invoicesType.value === PriceTypeEnum.RECURRING &&
      invoiceId.value
    ) {
      redirectOnParentRoute({
        name: 'payment-products-recurring-invoice-editor',
        params: {
          version: 'v2',
          location_id: route.params.locationId as string,
          scheduleId: invoiceId.value as string,
        },
      })
    } else if (invoices.value) {
      redirectOnParentRoute({
        name: 'payment-products-invoice-editor',
        params: {
          version: 'v2',
          location_id: route.params.locationId as string,
          invoiceId: invoices.value[invoices.value.length - 1],
        },
      })
    }
  }
  if (value === 'save_as_template') {
    await saveDocument()
    saveAsTemplate()
  }
  if (value === 'add_expiry') {
    openAddExpiryModal()
  }
}

const onSuccessFullAction = async () => {
  if (activeAction.value === 'delete') {
    await removeDocument()
    pushToListPage()
  }
  if (activeAction.value === DocumentStatus.COMPLETED) {
    await saveDocument()
    await markAsCompleted()
  }
  if (activeAction.value === DocumentStatus.DRAFT) {
    await convertToDraft()
  }
  closeActionModal()
}
</script>

<template>
  <DocumentValidationModal :show="showErrorModal" @on-cancel="toggleModal" />
  <AddExpiryModal
    :show="showAddExpiryModal"
    @on-cancel="toggleAddExpiryModal"
  />
  <CustomModal
    :loading="loading"
    :show="showActionConfirmationModal"
    modal-type="error"
    :success-action-text="$t('common.yes')"
    :cancel-action-text="$t('common.no')"
    :title="actionModalTitle"
    @on-cancel="closeActionModal"
    @on-success="onSuccessFullAction"
  >
    {{ actionModalDescription }}
  </CustomModal>
  <UIDropdown
    id="document-state-actions"
    :options="optionsList"
    @select="onSelect"
    :class="loading ? 'pointer-events-none opacity-50' : ''"
  >
    <UIButton
      :class="`hover:text-primary-600 ${
        loading ? 'pointer-events-none opacity-50' : ''
      }`"
      :loading="loading"
      id="document-state-actions-btn"
      :circle="true"
      :quaternary="true"
      ><DotsVerticalIcon class="w-5 h-5"
    /></UIButton>
  </UIDropdown>
</template>
