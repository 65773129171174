<script setup lang="ts">
import { useActionBarStore } from '@/store/actionBar'
import { InfoCircleIcon } from '@gohighlevel/ghl-icons/24/outline'
import {
  UIDrawer,
  UIDrawerContent,
  UITextMdMedium,
  UIRadioGroup,
  UIRadioGroupItem,
  UISpace,
  UITextSmMedium,
  UIDivider,
  UITooltip,
  UITextSmRegular,
} from '@gohighlevel/ghl-ui'
import { useI18n } from 'vue-i18n'
import OneTimePaymentSettings from './OneTimePaymentSettings.vue'
import RecurringPaymentSettings from './RecurringPaymentSettings.vue'
import { useProductListStore } from '@/store/productListStore'
import { computed, watch } from 'vue'
import { useBuilderStore } from '@/store/builder'
import { useCurrency } from '../../../composition/useCurrency'
import {
  Switch,
  PaymentScheduleSummary,
  PaymentDepositSchedule,
  PaymentSchedule,
  PriceTypeEnum,
} from '@gohighlevel/ghl-proposals-common'
import { useAppStore } from '@/store/app'

const appStore = useAppStore()
const { getCurrency } = useCurrency()
const { t } = useI18n()
const productListStore = useProductListStore()
const storeAction = useActionBarStore()
const builderStore = useBuilderStore()

const disabled = computed(() => !builderStore.isDocumentEditable)

const radioOptions = computed(() => [
  {
    id: PriceTypeEnum.ONETIME,
    title: t('common.onetime'),
    disabled: disabled.value,
  },
  {
    id: PriceTypeEnum.RECURRING,
    title: t('common.recurring'),
    disabled:
      (!builderStore.hasNoLineItems() && !builderStore.isRecurringProduct()) ||
      disabled.value,
  },
])
const onInvoiceCategoryTypeUpdate = (value: PriceTypeEnum) => {
  productListStore.setInvoiceType(value)
}
function onClose() {
  storeAction.togglePaymentSettings()
}

const currencyCode = computed(() => productListStore.currency || getCurrency())
const grandTotal = computed(
  () => productListStore.getGrandTotalForProductList() || 0
)
const updatePaymentSchedule = (paymentScheduleInfo: PaymentSchedule) => {
  productListStore.setPaymentDepositSchedule(paymentScheduleInfo)
}
const removePaymentSchedule = () => {
  productListStore.removePaymentDepositSchedule()
}
const isPaymentScheduleInfoExist = computed(
  () => Object.keys(productListStore.paymentDepositSchedule).length > 0
)
const onUpdateEnableAutoPayment = (value: boolean) => {
  productListStore.toggleEnableAutoPayment(value)
}
const enablePaymentDeposit = computed(() => {
  return (
    productListStore.invoiceType === PriceTypeEnum.ONETIME &&
    grandTotal.value > 0
  )
})
const enablePaymentDepositSchedule = computed(
  () => enablePaymentDeposit.value && builderStore.isDocumentEditable
)

const disableAutoPayment = computed(() => {
  return (
    !builderStore.isDocumentEditable ||
    (productListStore.invoiceType === PriceTypeEnum.ONETIME &&
      Object.keys(productListStore.paymentDepositSchedule).length === 0) ||
    grandTotal.value < 0
  )
})
const setAutoPayment = () => {
  if (
    (productListStore.invoiceType === PriceTypeEnum.ONETIME &&
      Object.keys(productListStore.paymentDepositSchedule).length === 0) ||
    grandTotal.value < 0
  ) {
    productListStore.toggleEnableAutoPayment(false)
  }
}
watch(
  () => [productListStore.invoiceType, productListStore.paymentDepositSchedule],
  ([_newInvoiceType, _newPaymentDepositSchedule]) => {
    if (_newInvoiceType || _newPaymentDepositSchedule) {
      setAutoPayment()
    }
  },
  { immediate: true }
)
</script>

<template>
  <UIDrawer
    :width="380"
    id="payment-settings-drawer"
    :show="storeAction.paymentSettings"
    @update:show="onClose"
    :placement="'left'"
    to="#drawer-target"
    class="!shadow-page h-screen"
    :trap-focus="false"
  >
    <UIDrawerContent
      id="payment-settings-drawer-content"
      :title="$t('common.paymentSettings')"
    >
      <UITextMdMedium class="mb-2">{{
        $t('common.invoiceType')
      }}</UITextMdMedium>
      <UITextSmRegular class="text-gray-700 mb-1">{{
        $t('common.recurringInvoiceChargeDescription')
      }}</UITextSmRegular>
      <UITextSmRegular class="text-gray-700 mb-1">{{
        $t('common.onetimeInvoiceChargeDescription')
      }}</UITextSmRegular>

      <UIRadioGroup
        :value="productListStore.invoiceType"
        name="invoiceType"
        class="mt-6 flex"
        @update:value="onInvoiceCategoryTypeUpdate"
      >
        <UISpace
          inline
          size="small"
          class="!flex-nowrap !justify-between flex-1"
        >
          <UIRadioGroupItem
            class="invoice-type-radio"
            v-for="item in radioOptions"
            :value="item.id"
            :disabled="item.disabled"
            v-bind:key="item.id"
          >
            <template #content>
              <UITextSmMedium>{{ item.title }}</UITextSmMedium>
            </template>
          </UIRadioGroupItem>
        </UISpace>
      </UIRadioGroup>
      <div class="-mx-6">
        <UIDivider class="mt-6" />
      </div>
      <RecurringPaymentSettings
        :disabled="disabled"
        v-if="productListStore.invoiceType === PriceTypeEnum.RECURRING"
      />
      <OneTimePaymentSettings />

      <div class="relative">
        <Switch
          class="mt-8"
          :value="productListStore.enableAutoPayment as boolean"
          :label="$t('common.enableAutoPayment')"
          :infoLabel="$t('common.enableAutoPaymentDescription')"
          @update:value="onUpdateEnableAutoPayment"
          :disabled="disableAutoPayment"
        />
        <div class="absolute top-0.5 left-[72%]">
          <UITooltip
            :content="$t('common.enableAutoPaymentInfo')"
            :placement="'top'"
          >
            <template #trigger>
              <InfoCircleIcon class="w-4 h-4 text-gray-500" />
            </template>
            {{ $t('common.enableAutoPaymentInfo') }}
          </UITooltip>
        </div>
      </div>

      <UIDivider />

      <PaymentScheduleSummary
        v-if="isPaymentScheduleInfoExist && enablePaymentDeposit"
        :paymentScheduleInfo="productListStore.paymentDepositSchedule"
        :currencyCode="currencyCode"
        :total="grandTotal"
        :isOuterBorder="true"
        :themeType="'vertical'"
        :currencyList="productListStore.currencyDetails?.currency"
      />
      <PaymentDepositSchedule
        class="pt-2"
        :locationId="appStore.document.locationId"
        :paymentScheduleInfo="productListStore.paymentDepositSchedule"
        :total="grandTotal"
        :currencyCode="currencyCode"
        :currencyList="productListStore.currencyDetails?.currency"
        :disable="!enablePaymentDepositSchedule"
        @payment-schedule-updated="updatePaymentSchedule"
        @payment-schedule-removed="removePaymentSchedule"
      />
    </UIDrawerContent>
  </UIDrawer>
</template>

<style lang="scss">
.invoice-type-radio {
  .hl-radio-group-item-container {
    flex-direction: row-reverse;
  }
}
#payment-settings-drawer {
  .hl-drawer-content {
    height: calc(100vh - 164px);
  }
}
</style>
