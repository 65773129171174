import { UIAvatar, getColorFromString } from '@gohighlevel/ghl-ui'
import { VNodeChild, h } from 'vue'
import {
  DOCUMENT_SENDER_ID,
  ELEMENTS_META,
  ELEMENTS_STYLES,
  GroupFields,
  GroupFieldsOptions,
  ICustomFieldsLinkageForm,
  IElement,
  IRecipient,
  ITax,
  ObjectForLinkedField,
  RecipientEntityTypeEnum,
  RecipientRolesEnum,
} from '../index'

import { getInitials } from '@gohighlevel/ghl-ui/src/utils/get-initials'
import { useTypography } from '../composition'

export const renderLabelAvatarOnSelect = (option: any): VNodeChild => {
  const { toCapitalize } = useTypography()
  const colorString = toCapitalize(
    option?.contactName || `${option.firstName} ${option.lastName}` || ''
  ).trim()
  return h(
    'div',
    {
      style: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '14px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
    [
      h(
        UIAvatar,
        {
          round: true,
          size: 24,
          style: {
            backgroundColor: getColorFromString(colorString as string),
            fontSize: '10px',
            flex: 'none',
          },
        },
        {
          default: () => getInitials(option.label as string),
        }
      ),
      h(
        'p',
        {
          style: {
            marginLeft: '8px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          },
        },
        { default: () => option.label as string }
      ),
    ]
  )
}

export const transformMenuOption = (arr: any) => {
  if (!arr) return null
  return arr.map(({ text, menu, value = text }: any) => ({
    label: text,
    value: value,
    children: menu ? transformMenuOption(menu) : undefined,
  }))
}
export const onChangeMarginPadding = (
  updateStyles: (key: string, value: any, id?: string, type?: string) => void
) => {
  return (args: { changed: { margin: any; padding: any } }) => {
    const { margin, padding } = args.changed
    if (margin) {
      if ('top' in margin) updateStyles(ELEMENTS_STYLES.MARGIN_TOP, margin.top)
      if ('bottom' in margin)
        updateStyles(ELEMENTS_STYLES.MARGIN_BOTTOM, margin.bottom)
      if ('left' in margin)
        updateStyles(ELEMENTS_STYLES.MARGIN_LEFT, margin.left)
      if ('right' in margin)
        updateStyles(ELEMENTS_STYLES.MARGIN_RIGHT, margin.right)
    }

    if (padding) {
      if ('top' in padding)
        updateStyles(ELEMENTS_STYLES.PADDING_TOP, padding.top)
      if ('bottom' in padding)
        updateStyles(ELEMENTS_STYLES.PADDING_BOTTOM, padding.bottom)
      if ('left' in padding)
        updateStyles(ELEMENTS_STYLES.PADDING_LEFT, padding.left)
      if ('right' in padding)
        updateStyles(ELEMENTS_STYLES.PADDING_RIGHT, padding.right)
    }
  }
}
export function isValidHandleBars(value: string) {
  const regex =
    /((^{{|[^{\n\r]{{)(?!{))(?!( ?(\w\.?)+ ?(}}(?!}))))|((^{{{|[^{\n\r]{{{)(?!{))(?!( ?(\w\.?)+ ?(}}}(?!}))))|{{4,}|^{(?= )/m

  return !regex.test(value) ? true : false
}

export function validateHandlebarVariableExistInCustomValues(
  value: string,
  staticCustomValues: Record<string, any>[],
  customValLoc: Record<string, any>[],
  customFieldLoc: Record<string, any>[],
  documentVariables: Record<string, any>[]
) {
  let customValues: any = []
  const docVarOptions = transformDocumentVariables(documentVariables)
  const options = getAllCustomValLocation(customValLoc)
  const cfOptions = [getAllCustomFieldLocation(customFieldLoc)]

  if (options && options.length > 0) {
    customValues = [
      ...staticCustomValues,
      ...options,
      ...docVarOptions,
      ...cfOptions,
    ]
  }

  const children = fetchAllCustomValueChild(customValues)
  const map: any = {}
  const errorCustomValues: any = []
  children.forEach((elem: { label: string; value: string }) => {
    map[elem.value.replace(/{{/g, '').replace(/}}/g, '').trim().toString()] =
      elem.label
  })

  const hbKeys: string[] | null = value.match(/{{\s*(\S+?)\s*}}/g)

  if (hbKeys) {
    for (const key of hbKeys) {
      const eachKey = key.replace(/{{/g, '').replace(/}}/g, '').trim()
      if (!(eachKey in map)) {
        errorCustomValues.push(eachKey)
      }
    }
  }

  return errorCustomValues
}

export const fetchAllCustomValueChild = (customVal: any[]) => {
  return customVal.reduce((result, item) => {
    const { children, ...rest } = item
    result.push(rest)
    if (children) {
      result.push(...fetchAllCustomValueChild(children))
    }
    return result
  }, [])
}

export const getAllCustomValLocation = (customVal: Record<string, any>[]) => {
  const options = []
  options.push({
    label: 'Custom Values',
    value: 'Custom Values',
    children: customVal,
  })
  return options
}

export const getAllCustomFieldLocation = (
  customFields: Record<string, any>[]
) => {
  const cf = {
    label: 'Custom Fields',
    value: 'Custom Fields',
    children: customFields,
  }
  return cf
}

export const transformDocumentVariables = (docVar: Record<string, any>[]) => {
  const options = []
  options.push({
    label: 'Document Variables',
    value: 'Document Variables',
    children: docVar?.map(({ fieldKey }) => ({
      label: fieldKey,
      value: `{{${fieldKey}}}`,
    })),
  })
  return options
}

export const convertToRecipientOptions = (list: IRecipient[]) => {
  return (
    list.map((contact: IRecipient) => ({
      ...contact,
      label: contact?.contactName || `${contact.firstName} ${contact.lastName}`,
      value: contact.id,
    })) || []
  )
}

export const getRecipientParamsFromContact = (
  contact: any,
  entityName: RecipientEntityTypeEnum = RecipientEntityTypeEnum.CONTACTS,
  isPrimary = false
) => {
  const { id, email, firstName, lastName, phone, country, contactName } =
    contact

  const contactDetails = {
    id,
    firstName,
    lastName: lastName || '',
    email,
    phone,
    country,
    isPrimary,
    contactName: contactName || null,
    hasCompleted: false,
    signingOrder: 1,
    shareLink: '',
    imgUrl: '',
    entityName,
    role: RecipientRolesEnum.USER,
  }
  return contactDetails
}

/**
 * Sets appropriate properties required for a normal recipient to become the primary signer
 * @param recipient
 * @returns
 */
export const upgradeRecipientToPrimarySigner = (recipient: IRecipient) => {
  return {
    ...recipient,
    role: RecipientRolesEnum.SIGNER,
    isPrimary: true,
  }
}

/**
 * Returns recipients who are dynamically to be added in the recipient list
 * eg: elems marked as sender require the current loggedin user to be as the recipient
 * @param recipients
 * @param fillableElements
 * @param userData
 * @returns
 */
export const getAdditionalRecipientsWithRoles = (
  recipients: IRecipient[],
  fillableElements: IElement[],
  userData: any
) => {
  if (!fillableElements) return []
  const currentUser = getRecipientParamsFromContact(
    userData,
    RecipientEntityTypeEnum.USERS,
    false
  )
  const senderUserExists = fillableElements.some(elem => {
    return elem.component?.options?.recipient === DOCUMENT_SENDER_ID
  })
  if (senderUserExists) {
    const contactDetails = {
      ...currentUser,
      contactName: `${currentUser.firstName} ${currentUser.lastName}`,
    }

    if (!recipients.find(elem => elem.id === contactDetails.id)) {
      return [contactDetails]
    }
  }
  return []
}

export const removeUserFromRecipientIfElementIsNotAssigned = (
  signatureElements: any[],
  recipients: any[]
) => {
  const signatureUserIds = signatureElements.reduce((acc, signature) => {
    if (
      signature.component?.options[ELEMENTS_META.RECIPIENT] &&
      signature.component?.options[ELEMENTS_META.ENTITY_NAME] ===
        RecipientEntityTypeEnum.USERS
    ) {
      acc.push(
        `${signature.component.options[ELEMENTS_META.RECIPIENT]}_${
          signature.component.options[ELEMENTS_META.ENTITY_NAME]
        }`
      )
    }
    return acc
  }, [] as string[])
  const recipientList = recipients.filter(elem => {
    return (
      elem.entityName === RecipientEntityTypeEnum.CONTACTS ||
      (elem.entityName === RecipientEntityTypeEnum.USERS &&
        signatureUserIds.indexOf(`${elem.id}_${elem.entityName}`) > -1) ||
      (elem.entityName === RecipientEntityTypeEnum.USERS &&
        elem.id === DOCUMENT_SENDER_ID)
    )
  })
  const recipientWithRolesList = updateRecipientRolesIfElementIsAssigned(
    signatureUserIds,
    recipientList
  )
  return recipientWithRolesList
}

export const getUserIdsFromSignElements = (signatureElements: any[]) => {
  const signatureUserIds = signatureElements.reduce((acc, signature) => {
    if (
      signature.component?.options[ELEMENTS_META.RECIPIENT] &&
      signature.component?.options[ELEMENTS_META.ENTITY_NAME]
    ) {
      acc.push(
        `${signature.component.options[ELEMENTS_META.RECIPIENT]}_${
          signature.component.options[ELEMENTS_META.ENTITY_NAME]
        }`
      )
    }
    return acc
  }, [] as string[])

  return signatureUserIds
}

export const updateRecipientRolesIfElementIsAssigned = (
  signatureElements: any[],
  recipients: any[]
) => {
  const signatureUserIds = getUserIdsFromSignElements(signatureElements)
  const recipientList = recipients.map(recipient => {
    return signatureUserIds.indexOf(`${recipient.id}_${recipient.entityName}`) >
      -1
      ? { ...recipient, role: RecipientRolesEnum.SIGNER }
      : { ...recipient, role: RecipientRolesEnum.USER }
  })
  return recipientList
}

export const fetchPrimaryRecipient = (recipients: IRecipient[]) => {
  if (recipients && recipients.length > 0) {
    return recipients.find(elem => {
      return elem.isPrimary === true
    })
  }
  return null
}

export const recipientThumbnail = (firstName = '', lastName = '') => {
  const initials = getInitials(`${firstName} ${lastName}`)
  return initials.length > 0 ? initials : ''
}

export const getMarginProperties = (element?: IElement) => {
  return {
    top: element?.responsiveStyles?.large?.marginTop || '0px',
    bottom: element?.responsiveStyles?.large?.marginBottom || '0px',
    right: element?.responsiveStyles?.large?.marginRight || '0px',
    left: element?.responsiveStyles?.large?.marginLeft || '0px',
  }
}

export const getPaddingProperties = (element?: IElement) => {
  return {
    top: element?.responsiveStyles?.large?.paddingTop || '0px',
    bottom: element?.responsiveStyles?.large?.paddingBottom || '0px',
    right: element?.responsiveStyles?.large?.paddingRight || '0px',
    left: element?.responsiveStyles?.large?.paddingLeft || '0px',
  }
}

export const extractRecipientIdAndEntityName = (val: string) => {
  const recipientData = val.split('_')
  if (recipientData.length === 2) {
    const recipientId = recipientData[0]
    const entityName = recipientData[1]
    return { recipientId, entityName }
  }
  return { recipientId: null, entityName: null }
}

export const getTaxItemName = (tax: ITax, taxInclusive: boolean) => {
  if (taxInclusive) return `${tax.name} (${tax.rate}% included in prices)`
  return tax.name
}

export const removeFillableElementsWhichIsPartOfGroup = (
  allFillableElements: IElement[],
  groups: GroupFields<GroupFieldsOptions>[]
) => {
  const groupElementsList = groups.reduce((acc, g) => {
    g.children?.forEach(element => {
      return acc.push(element.elementId)
    })
    return acc
  }, [] as any[])

  const allFillableElementsNotPartOfGroups = allFillableElements.filter(
    element => !groupElementsList.includes(element.id)
  )
  return allFillableElementsNotPartOfGroups
}

export const removeOpportunityCustomFields = (customFieldOptions: {
  label: string
  value: string
  children: {
    children: { label: string; value: string }[]
    label: string
    value: string
  }[]
}) => {
  if (
    customFieldOptions &&
    customFieldOptions.children &&
    customFieldOptions.children.length > 0
  ) {
    const filedOptions = customFieldOptions?.children?.filter(item => {
      const hasOpportunityChild = item.children.some(child =>
        child.value.startsWith('{{ opportunity.')
      )
      return !hasOpportunityChild
    })
    customFieldOptions['children'] = filedOptions
    return customFieldOptions
  } else {
    return customFieldOptions
  }
}

export const generateCustomLinkItemPayload = (
  formValue: ICustomFieldsLinkageForm
) => {
  const fieldKey =
    formValue.objectName === ObjectForLinkedField.DOCUMENT_VARIABLE
      ? formValue.documentFieldKey
      : formValue.customFieldKey

  return {
    id: formValue.customFieldId || '',
    fieldKey: fieldKey || '',
    value: '',
    dataType: formValue.customFieldType || '',
    objectType: formValue.objectName || '',
    category: formValue.customFieldCategory || '',
    fieldLabel: formValue.customFieldLabel || '',
  }
}

export function escape(input: string, withExt = true): string {
  const fileNameRegex = /[^A-Za-z0-9\-_.]/ // Special characters allowed in a filename

  const [fileName, fileExtension] = input.split('.')

  if (fileNameRegex.test(fileName)) {
    const escapedFileName = fileName.replace(
      /[^A-Za-z0-9\-_.]/g,
      function (char: string): string {
        return '%' + char.charCodeAt(0).toString(16).toUpperCase()
      }
    )

    if (withExt) {
      return `${escapedFileName}.${fileExtension}`
    }
    return escapedFileName
  }

  return input
}

export const getFileName = (fileName: string, documentId: string) => {
  return escape(
    (fileName &&
      fileName.replace(/\\/g, '').replace(/\s/g, '_').replace(/\//g, '_')) ||
      `document_${documentId}`,
    false
  )
}

export async function downloadCSV(
  csv: string,
  documentName: string,
  documentId: string
) {
  try {
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = `${getFileName(documentName, documentId)}.csv`
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    URL.revokeObjectURL(url)
  } catch (error) {
    console.error('Error downloading the CSV:', error)
  }
}

export function splitAtFirstColon(input: string): [string, string] {
  const index = input.indexOf(':')
  if (index === -1) {
    return [input, '']
  }
  return [input.slice(0, index), input.slice(index + 1)]
}

export function isValidUrl(url: string): boolean {
  const urlRegex = /^(https?:\/\/)([\w-]+(\.[\w-]+)+)([/?#]\S*)?$/i
  return urlRegex.test(url)
}
