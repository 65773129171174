<script setup lang="ts">
import {
  UIUpload,
  UITextSmRegular,
  UITextXsRegular,
  UIProgress,
  UITextSmMedium,
  UIButton,
} from '@gohighlevel/ghl-ui'
import {
  File06Icon,
  Trash01Icon,
  CheckDone02Icon,
} from '@gohighlevel/ghl-icons/24/outline'
import {
  CustomModal,
  ModalEvents,
  DraggableList,
  bytes,
  DragHandleSVG,
} from '@gohighlevel/ghl-proposals-common'
import { useDocument, useUploadPDF } from '@/composition'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { AxiosProgressEvent } from 'axios'
import { useRoute } from 'vue-router'
import { useAppStore } from '@/store/app'

const { t } = useI18n()
const document = useDocument()
const appStore = useAppStore()
const emits = defineEmits([ModalEvents.CLOSE_MODAL, ModalEvents.SHOW_MODAL])
const fileList = ref<
  {
    id: string
    name: string
    batchId?: string | null
    percentage?: number | null
    status: 'pending' | 'uploading' | 'finished' | 'removed' | 'error'
    url?: string | null
    file?: File | null
    thumbnailUrl?: string | null
    type?: string | null
    fullPath?: string | null
    order: number
  }[]
>([])

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  documentId: {
    type: String,
    required: true,
  },
  isAppend: {
    type: Boolean,
    default: true,
  },
  appendImagesFromIndex: {
    type: Number,
    default: 0,
  },
})

const {
  uploadPDF,
  isProcessing,
  isUploading,
  uploadPercentage,
  errorMessage,
  isRedirecting,
  isRedirected,
  resetErrorMessage,
  cancelProcessing,
  setError,
  processingPercentage,
  cancelUpload,
  initAppendedFileProcessing,
} = useUploadPDF()

watch(isRedirected, () => {
  onCancel()
})

const isCancelled = ref(false)
const route = useRoute()
const uploadInBatches = async (
  list: { file: File; order: number; documentId: string }[],
  batchSize = 2
) => {
  const results: any[] = []
  let index = 0

  const processNext = async () => {
    if (index >= list.length) return

    const { file, order, documentId } = list[index]
    const currentIndex = index
    index++

    fileList.value[currentIndex].status = 'uploading'

    try {
      const response = await uploadPDF(
        file,
        documentId,
        order,
        (progressEvent: AxiosProgressEvent) => {
          const { loaded, total } = progressEvent
          const percent = Math.round((loaded / total) * 100)
          fileList.value[currentIndex].percentage = percent
        }
      )
      if (response) {
        const { url, storagePath, type } = response
        if (fileList.value[currentIndex]) {
          fileList.value[currentIndex].status = 'finished'
          results.push({ url, storagePath, type, order })
          await processNext()
        }
      }
    } catch (error) {
      console.error(`Error uploading file ${order}:`, error)
    }
  }

  const initialBatch = []
  while (initialBatch.length < batchSize && index < list.length) {
    initialBatch.push(processNext())
  }

  await Promise.all(initialBatch)
  if (!isCancelled.value) {
    //First save the document changes
    appStore.setUnsavedChanges(true)
    await document.saveDocument()
    await initAppendedFileProcessing({
      documentId: list[0].documentId,
      locationId: route.params.locationId as string,
      files: results,
      isAppend: props.isAppend,
      appendImagesFromIndex: props.appendImagesFromIndex,
    })
  }
}

const onSuccess = async () => {
  isCancelled.value = false
  try {
    isUploading.value = true
    await uploadInBatches(
      fileList.value.map(file => ({
        file: file.file,
        order: file.order,
        documentId: props.documentId as string,
      })),
      2
    )
  } catch (error) {
    setError('Something went wrong.')
  }
}

const onCancel = async () => {
  isCancelled.value = true
  await resetErrorMessage()
  await cancelProcessing()
  fileList.value = []
  await cancelUpload()
  notHasError.value = true
  emits(ModalEvents.CLOSE_MODAL, false)
}

const notHasError = ref(true)

const onRemove = (i: number) => {
  const updatedFileList = [...fileList.value]
  updatedFileList.splice(i, 1)
  onFileListUpdate(updatedFileList)
}

const loadingText = computed(() => {
  if (isRedirecting.value) {
    return t('common.mergingFiles')
  }
  if (isProcessing.value) {
    return t('proposalsList.loading.fileProcessing')
  }
  if (isUploading.value) {
    if (uploadPercentage.value === 100) {
      return t('proposalsList.loading.uploadComplete')
    }
    return `${t('proposalsList.loading.uploading')} (${
      uploadPercentage.value
    }%)`
  }
  return t('proposalsList.loading.filePrepare')
})

const onFileListUpdate = (updatedFileList: any) => {
  notHasError.value = updatedFileList.some(({ file }) =>
    bytes.isGreaterThanOrEq(file.size, 50)
  )
  fileList.value = updatedFileList.map((file: any, index: number) => ({
    ...file,
    order: index + 1,
    status: 'pending',
    disabled: notHasError.value
      ? !bytes.isGreaterThanOrEq(file.file.size, 50)
      : false,
  }))

  resetErrorMessage()
}

const reorderArray = ({
  oldIndex,
  newIndex,
}: {
  oldIndex: number
  newIndex: number
}) => {
  const newArray = [...(fileList?.value || [])]
  const movedItem = newArray.splice(oldIndex, 1)[0]
  newArray.splice(newIndex, 0, movedItem)

  // Update the signingOrder property
  if (oldIndex < newIndex) {
    for (let i = oldIndex; i <= newIndex; i++) {
      newArray[i].order = i + 1
    }
  } else {
    for (let i = newIndex; i <= oldIndex; i++) {
      newArray[i].order = i + 1
    }
  }
  fileList.value = newArray
  return newArray
}
// const svg = computed(() => DragHandleSVG)
const draggable = computed(() => !(isUploading.value || notHasError.value))
</script>

<template>
  <CustomModal
    :headerIcon="false"
    :title="$t('proposalsList.uploadFiles')"
    :description="$t('proposalsList.uploadFilesDesc')"
    :modalType="'primary'"
    :show="show"
    :successActionText="$t('common.upload')"
    :cancelActionText="isUploading ? '' : $t('common.cancel')"
    @on-success="onSuccess"
    @on-cancel="onCancel"
    :close-on-success="false"
    :loading="isProcessing || isUploading || isRedirecting"
    :disabled="isProcessing || isUploading || notHasError"
  >
    <div class="relative">
      <UITextXsRegular class="text-danger mb-1">{{
        errorMessage
      }}</UITextXsRegular>
      <div
        :style="{
          backgroundColor: 'rgba(255,255,255,1)',
        }"
        class="flex items-center justify-center w-full h-full absolute z-10"
        v-if="isProcessing"
      >
        <div class="inline-flex flex-col items-center w-full">
          <div class="w-full text-center">
            <UIProgress
              type="line"
              :percentage="processingPercentage"
              indicator-placement="inside"
              :processing="true"
            />
          </div>

          <UITextXsRegular>{{ loadingText }}</UITextXsRegular>
        </div>
      </div>
      <UIUpload
        :multiple="true"
        :max="10"
        :file-list="fileList"
        accept=".pdf"
        @update:file-list="onFileListUpdate"
        :disabled="isUploading"
      >
        <template #extra>
          <UITextSmRegular>
            {{ $t('proposalsList.dragFileUpload') }}
          </UITextSmRegular>
          <UITextXsRegular
            >{{ $t('proposalsList.supportedPdf') }}
          </UITextXsRegular>
          <UITextXsRegular>{{ $t('common.max10Files') }} </UITextXsRegular>
        </template>
      </UIUpload>
      <DraggableList
        :list="fileList"
        @on-update="reorderArray"
        :draggable="draggable"
        class="px-2 file-drag--list max-h-[20em] overflow-auto border-b"
      >
        <template #listItem="slotProps">
          <div
            class="bg-white file-box"
            :class="{
              'invalid-file': bytes.isGreaterThanOrEq(
                slotProps.data.file.size,
                50
              ),
              'opacity-50 pointer-events-none cursor-not-allowed':
                slotProps.data.disabled,
            }"
          >
            <div class="w-full flex items-center">
              <div
                class="w-10/12 flex items-center rounded-sm my-2 recipient-drag"
              >
                <div class="handle w-1/12 flex">
                  <DragHandleSVG class="remove-selecto-selection" />
                </div>
                <div class="flex items-center flex-1 max-w-full w-11/12">
                  <div class="w-2/12">
                    <span
                      class="h-10 w-10 rounded-full bg-gray-100 flex justify-center items-center mr-2"
                    >
                      <File06Icon class="w-5 h-5" />
                    </span>
                  </div>
                  <div class="w-10/12 flex flex-col flex-1">
                    <UITextSmMedium class="ellipsis">{{
                      slotProps.data.name
                    }}</UITextSmMedium>
                    <UITextXsRegular>{{
                      bytes.format(slotProps.data.file.size)
                    }}</UITextXsRegular>
                    <UITextXsRegular
                      class="text-error-700"
                      v-if="
                        bytes.isGreaterThanOrEq(slotProps.data.file.size, 50)
                      "
                      >{{ t('common.maxSize50MB') }}</UITextXsRegular
                    >
                  </div>
                </div>
              </div>

              <div class="w-2/12 flex justify-end items-center">
                <UIProgress
                  v-if="isUploading"
                  :percentage="slotProps.data.percentage"
                  type="circle"
                  :processing="true"
                  :showIndicator="false"
                  :circleGap="0"
                  :gapDegree="0"
                  :height="2"
                  :offsetDegree="0"
                  :strokeWidth="12"
                  unit="%"
                >
                  <template #default>
                    <CheckDone02Icon class="w-4 h-4 text-success-500" />
                  </template>
                </UIProgress>
                <UIButton
                  v-if="!isUploading"
                  :id="`delete-file-${slotProps.index}`"
                  type="default"
                  size="medium"
                  :disabled="false"
                  :loading="false"
                  :ghost="false"
                  :quaternary="true"
                  :circle="true"
                  :text="false"
                  @click="() => onRemove(slotProps.index)"
                >
                  <Trash01Icon
                    class="w-4 h-4"
                    :class="{
                      'text-error-700': bytes.isGreaterThanOrEq(
                        slotProps.data.file.size,
                        50
                      ),
                    }"
                  />
                </UIButton>
              </div>
            </div>
          </div>
        </template>
      </DraggableList>
    </div>
  </CustomModal>
</template>

<style lang="scss">
.file-drag {
  &--list {
    padding: 0 !important;
    .list-group-item {
      margin: 1em 0px;
      padding: 0em 0px;
      border: none !important;
    }
    .n-progress.n-progress--circle.n-progress--default {
      width: 30px;
    }
    .file-box {
      border-radius: 12px;
      padding: 8px 12px;
    }
    .invalid-file {
      position: relative;
      background-color: var(--error-25) !important;
      color: var(--error-700);
      border: 1px solid var(--error-300);
    }
  }
}
.n-upload-trigger {
  .upload-icon {
    width: 85px;
    height: 75px;
    padding: 10px;

    svg {
      width: 54px;
      height: 54px;
    }
  }
}
.n-upload-file-list {
  display: none;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
