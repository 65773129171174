import {
  CheckBoxElementOptions,
  DATE_FIELD_DATE_VALIDATION,
  DEFAULT_DATE_FORMAT,
  DateFieldElementOptions,
  DiscountType,
  ELEMENTS_LOOKUP,
  ELEMENTS_META,
  ELEMENTS_NAME,
  ELEMENTS_STYLES,
  ICreateElement,
  IElement,
  ImgElementOptions,
  InitialsElementOptions,
  PageBreakElementOptions,
  PageElementOptions,
  PaymentElementOptions,
  PaymentSchedule,
  PaymentType,
  ProductListElementOptions,
  SignatureElementOptions,
  TableElementOptions,
  TextElementOptions,
  TextFieldElementOptions,
  VideoElementOptions,
  fetchPrimaryRecipient,
  getNextId,
  pageSizesConfig,
} from '@gohighlevel/ghl-proposals-common'

import { useCurrency } from '@/composition'
import { useAppStore } from '@/store/app'
import { useBuilderStore } from '@/store/builder'
import { useProductListStore } from '@/store/productListStore'
import { generateId } from '@/util'
import i18n from '@gohighlevel/ghl-proposals-common/src/locales'
import { ref } from 'vue'

export const generateElementId = (): string => generateId()
const textFieldCounter = ref(1)
export const useTextFieldIdCounter = () => {
  const getNextCount = () => {
    const nextCount = textFieldCounter.value
    textFieldCounter.value += 1
    return nextCount
  }
  return {
    getNextCount,
  }
}

const createTextElement = (): IElement<TextElementOptions> => {
  return {
    type: ELEMENTS_LOOKUP.TEXT,
    version: 1,
    id: generateElementId(),
    children: [],
    component: {
      name: ELEMENTS_NAME[ELEMENTS_LOOKUP.TEXT],
      options: {
        [ELEMENTS_META.TEXT]: `
        <h2 style="line-height: 1.15; text-align: left; line-height: 1.15; font-family: sans-serif;"><span style="font-family: sans-serif; font-size: 32px;">${i18n.global.t(
          'proposals.heading'
        )}</span></h2><p style="line-height: 1.25">${i18n.global.t(
          'proposals.addText'
        )}</p>
        `,
      },
    },
    responsiveStyles: {
      large: {
        [ELEMENTS_STYLES.PADDING_TOP]: `10px`,
        [ELEMENTS_STYLES.PADDING_BOTTOM]: `10px`,
        [ELEMENTS_STYLES.PADDING_LEFT]: `10px`,
        [ELEMENTS_STYLES.PADDING_RIGHT]: `10px`,
        [ELEMENTS_STYLES.MARGIN_TOP]: null,
        [ELEMENTS_STYLES.MARGIN_BOTTOM]: null,
        [ELEMENTS_STYLES.MARGIN_LEFT]: null,
        [ELEMENTS_STYLES.MARGIN_RIGHT]: null,
        [ELEMENTS_STYLES.BACKGROUND_COLOR]: '',
      },
    },
  }
}

const createImageElement = (): IElement<ImgElementOptions> => {
  return {
    type: ELEMENTS_LOOKUP.IMAGE,
    version: 1,
    id: generateElementId(),
    children: [],
    component: {
      name: ELEMENTS_NAME[ELEMENTS_LOOKUP.IMAGE],
      options: {
        [ELEMENTS_META.SRC]: '',
        [ELEMENTS_META.ALT_TEXT]: '',
        [ELEMENTS_META.HREF]: '',
      },
    },
    responsiveStyles: {
      large: {
        [ELEMENTS_STYLES.ALIGN]: 'img-center',
        [ELEMENTS_STYLES.PADDING_TOP]: `10px`,
        [ELEMENTS_STYLES.PADDING_BOTTOM]: `10px`,
        [ELEMENTS_STYLES.PADDING_LEFT]: `10px`,
        [ELEMENTS_STYLES.PADDING_RIGHT]: `10px`,
        [ELEMENTS_STYLES.MARGIN_TOP]: null,
        [ELEMENTS_STYLES.MARGIN_BOTTOM]: null,
        [ELEMENTS_STYLES.MARGIN_LEFT]: null,
        [ELEMENTS_STYLES.MARGIN_RIGHT]: null,
        [ELEMENTS_STYLES.BACKGROUND_COLOR]: '',
        [ELEMENTS_STYLES.HEIGHT]: '',
        [ELEMENTS_STYLES.WIDTH]: '',
        [ELEMENTS_STYLES.IMAGE_EFFECT]: 'img-full-color',
      },
    },
  }
}

const createVideoElement = (): IElement<VideoElementOptions> => {
  return {
    type: ELEMENTS_LOOKUP.VIDEO,
    version: 1,
    id: generateElementId(),
    children: [],
    component: {
      name: ELEMENTS_NAME[ELEMENTS_LOOKUP.VIDEO],
      options: {
        [ELEMENTS_META.SRC]: '',
        [ELEMENTS_META.HTML]: '',
      },
    },
    responsiveStyles: {
      large: {
        [ELEMENTS_STYLES.PADDING_TOP]: `10px`,
        [ELEMENTS_STYLES.PADDING_BOTTOM]: `10px`,
        [ELEMENTS_STYLES.PADDING_LEFT]: `10px`,
        [ELEMENTS_STYLES.PADDING_RIGHT]: `10px`,
        [ELEMENTS_STYLES.MARGIN_TOP]: null,
        [ELEMENTS_STYLES.MARGIN_BOTTOM]: null,
        [ELEMENTS_STYLES.MARGIN_LEFT]: null,
        [ELEMENTS_STYLES.MARGIN_RIGHT]: null,
        [ELEMENTS_STYLES.BACKGROUND_COLOR]: '',
        [ELEMENTS_STYLES.HEIGHT]: '',
        [ELEMENTS_STYLES.WIDTH]: '',
        [ELEMENTS_STYLES.ALIGN]: 'ml-auto',
      },
    },
  }
}

const createTableElement = (): IElement<TableElementOptions> => {
  return {
    type: ELEMENTS_LOOKUP.TABLE,
    version: 1,
    id: generateElementId(),
    children: [],
    component: {
      name: ELEMENTS_NAME[ELEMENTS_LOOKUP.TABLE],
      options: {
        text: `<table>
        <tbody>
          <tr>
            <th></th>
            <th></th>
          </tr>
          <tr>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>`,
      },
    },
    responsiveStyles: {
      large: {
        [ELEMENTS_STYLES.PADDING_TOP]: `10px`,
        [ELEMENTS_STYLES.PADDING_BOTTOM]: `10px`,
        [ELEMENTS_STYLES.PADDING_LEFT]: `10px`,
        [ELEMENTS_STYLES.PADDING_RIGHT]: `10px`,
        [ELEMENTS_STYLES.MARGIN_TOP]: null,
        [ELEMENTS_STYLES.MARGIN_BOTTOM]: null,
        [ELEMENTS_STYLES.MARGIN_LEFT]: null,
        [ELEMENTS_STYLES.MARGIN_RIGHT]: null,
      },
    },
  }
}

const createPageBreakElement = (): IElement<PageBreakElementOptions> => {
  return {
    type: ELEMENTS_LOOKUP.PAGE_BREAK,
    version: 1,
    id: generateElementId(),
    children: [],
    component: {
      name: ELEMENTS_NAME[ELEMENTS_LOOKUP.PAGE_BREAK],
      options: {},
    },
    responsiveStyles: {
      large: {},
    },
  }
}

const createProductListElement = (): IElement<ProductListElementOptions> => {
  const { getCurrency } = useCurrency()
  const productListStore = useProductListStore()
  return {
    type: ELEMENTS_LOOKUP.PRODUCT_LIST,
    version: 4,
    id: generateElementId(),
    children: [],
    component: {
      name: ELEMENTS_NAME[ELEMENTS_LOOKUP.PRODUCT_LIST],
      options: {
        [ELEMENTS_META.CURRENCY_CODE]:
          productListStore.currency || getCurrency(),
        [ELEMENTS_META.LINE_ITEMS]: [],
        [ELEMENTS_META.DISCOUNT_VALUE]: 0,
        [ELEMENTS_META.DISCOUNT_TYPE]: DiscountType.PERCENTAGE,
        [ELEMENTS_META.PAYMENT_SCHEDULES]: [],
        [ELEMENTS_META.PAYMENT_STATUS]: {
          paymentMode: PaymentType.CASH,
          paidOn: Date.now(),
        } as unknown as PaymentSchedule,
      } as unknown as ProductListElementOptions,
    },
    responsiveStyles: {
      large: {
        [ELEMENTS_STYLES.PADDING_TOP]: `10px`,
        [ELEMENTS_STYLES.PADDING_BOTTOM]: `10px`,
        [ELEMENTS_STYLES.PADDING_LEFT]: `10px`,
        [ELEMENTS_STYLES.PADDING_RIGHT]: `10px`,
        [ELEMENTS_STYLES.MARGIN_TOP]: null,
        [ELEMENTS_STYLES.MARGIN_BOTTOM]: null,
        [ELEMENTS_STYLES.MARGIN_LEFT]: null,
        [ELEMENTS_STYLES.MARGIN_RIGHT]: null,
      },
    },
  }
}

const createPaymentDetailsElement = (): IElement<PaymentElementOptions> => {
  return {
    type: ELEMENTS_LOOKUP.PAYMENT_DETAILS,
    version: 1,
    id: generateElementId(),
    children: [],
    component: {
      name: ELEMENTS_NAME[ELEMENTS_LOOKUP.PAYMENT_DETAILS],
      options: {},
    },
    responsiveStyles: {
      large: {},
    },
  }
}

const createSignatureElement = (): IElement<SignatureElementOptions> => {
  const appStore = useAppStore()
  const recipient = fetchPrimaryRecipient(appStore.document?.recipients)
  const primaryRecipient = recipient ? recipient.id : null
  const entityName = recipient?.entityName || null

  return {
    type: ELEMENTS_LOOKUP.SIGNATURE,
    version: 2,
    id: generateElementId(),
    children: [],
    component: {
      isDraggable: true,
      name: ELEMENTS_NAME[ELEMENTS_LOOKUP.SIGNATURE],
      options: {
        isGhost: true,
        showName: true,
        [ELEMENTS_META.TEXT]: 'Signature',
        [ELEMENTS_META.REQUIRED]: true,
        [ELEMENTS_META.FIELD_ID]: 'signature1',
        [ELEMENTS_META.SRC]: '',
        [ELEMENTS_META.RECIPIENT]: primaryRecipient,
        [ELEMENTS_META.TIMESTAMP]: null,
        [ELEMENTS_META.ENTITY_NAME]: entityName,
      } as SignatureElementOptions,
    },
    responsiveStyles: {
      large: {
        [ELEMENTS_STYLES.ALIGN]: 'signature-left',
        [ELEMENTS_STYLES.PADDING_TOP]: `0px`,
        [ELEMENTS_STYLES.PADDING_BOTTOM]: `0px`,
        [ELEMENTS_STYLES.PADDING_LEFT]: `0px`,
        [ELEMENTS_STYLES.PADDING_RIGHT]: `0px`,
        [ELEMENTS_STYLES.MARGIN_TOP]: null,
        [ELEMENTS_STYLES.MARGIN_BOTTOM]: null,
        [ELEMENTS_STYLES.MARGIN_LEFT]: null,
        [ELEMENTS_STYLES.MARGIN_RIGHT]: null,
        dimensions: {
          width: 190,
          height: 68,
        },
      },
    },
  }
}

const createPage = (): IElement<PageElementOptions> => {
  return {
    type: ELEMENTS_LOOKUP.PAGE,
    version: 2,
    children: [],
    id: generateElementId(),
    component: {
      name: ELEMENTS_NAME[ELEMENTS_LOOKUP.PAGE],
      options: {
        [ELEMENTS_META.SRC]: '',
        [ELEMENTS_META.PAGE_DIMENSIONS]: pageSizesConfig['Letter'],
      },
    },
    responsiveStyles: {
      large: {
        [ELEMENTS_STYLES.BACKGROUND_COLOR]: '#ffffff',
        [ELEMENTS_STYLES.BACKGROUND_POSITION]: 'top left',
        [ELEMENTS_STYLES.BACKGROUND_SIZE]: 'cover',
        [ELEMENTS_STYLES.BACKGROUND_REPEAT]: 'repeat',
        [ELEMENTS_STYLES.BACKGROUND_OPACITY]: 100,
      },
    },
  }
}

const createTextField = (
  counter: number
): IElement<TextFieldElementOptions> => {
  const appStore = useAppStore()
  const recipient = fetchPrimaryRecipient(appStore.document?.recipients)
  const primaryRecipient = recipient ? recipient.id : null
  const entityName = recipient?.entityName || null
  const fieldId = `text_field_${counter}`
  return {
    type: ELEMENTS_LOOKUP.TEXT_FIELD,
    version: 1,
    id: generateElementId(),
    children: [],
    component: {
      isDraggable: true,
      name: ELEMENTS_NAME[ELEMENTS_LOOKUP.TEXT_FIELD],
      options: {
        isGhost: true,
        [ELEMENTS_META.TEXT]: '',
        [ELEMENTS_META.REQUIRED]: true,
        [ELEMENTS_META.FIELD_ID]: fieldId,
        [ELEMENTS_META.SRC]: '',
        [ELEMENTS_META.RECIPIENT]: primaryRecipient,
        [ELEMENTS_META.TIMESTAMP]: null,
        [ELEMENTS_META.ENTITY_NAME]: entityName,
        [ELEMENTS_META.PLACEHOLDER]: 'Enter value',
      } as TextFieldElementOptions,
    },
    responsiveStyles: {
      large: {
        [ELEMENTS_STYLES.PADDING_TOP]: `0px`,
        [ELEMENTS_STYLES.PADDING_BOTTOM]: `0px`,
        [ELEMENTS_STYLES.PADDING_LEFT]: `0px`,
        [ELEMENTS_STYLES.PADDING_RIGHT]: `0px`,
        [ELEMENTS_STYLES.MARGIN_TOP]: null,
        [ELEMENTS_STYLES.MARGIN_BOTTOM]: null,
        [ELEMENTS_STYLES.MARGIN_LEFT]: null,
        [ELEMENTS_STYLES.MARGIN_RIGHT]: null,
        dimensions: {
          height: 26,
          width: 165,
        },
      },
    },
  }
}

const createDateField = (
  counter: number
): IElement<DateFieldElementOptions> => {
  const appStore = useAppStore()
  const recipient = fetchPrimaryRecipient(appStore.document?.recipients)
  const primaryRecipient = recipient ? recipient.id : null
  const entityName = recipient?.entityName || null
  const fieldId = `date_field_${counter}`
  return {
    type: ELEMENTS_LOOKUP.DATE_FIELD,
    version: 1,
    id: generateElementId(),
    children: [],
    component: {
      isDraggable: true,
      name: ELEMENTS_NAME[ELEMENTS_LOOKUP.TEXT_FIELD],
      options: {
        isGhost: true,
        [ELEMENTS_META.TEXT]: '',
        [ELEMENTS_META.REQUIRED]: true,
        [ELEMENTS_META.FIELD_ID]: fieldId,
        [ELEMENTS_META.SRC]: '',
        [ELEMENTS_META.RECIPIENT]: primaryRecipient,
        [ELEMENTS_META.TIMESTAMP]: null,
        [ELEMENTS_META.ENTITY_NAME]: entityName,
        [ELEMENTS_META.PLACEHOLDER]: 'Select date',
        [ELEMENTS_META.AVAILABLE_DATES]: DATE_FIELD_DATE_VALIDATION.ANY,
        [ELEMENTS_META.DATE_FORMAT]: DEFAULT_DATE_FORMAT,
      } as DateFieldElementOptions,
    },
    responsiveStyles: {
      large: {
        [ELEMENTS_STYLES.PADDING_TOP]: `0px`,
        [ELEMENTS_STYLES.PADDING_BOTTOM]: `0px`,
        [ELEMENTS_STYLES.PADDING_LEFT]: `0px`,
        [ELEMENTS_STYLES.PADDING_RIGHT]: `0px`,
        [ELEMENTS_STYLES.MARGIN_TOP]: null,
        [ELEMENTS_STYLES.MARGIN_BOTTOM]: null,
        [ELEMENTS_STYLES.MARGIN_LEFT]: null,
        [ELEMENTS_STYLES.MARGIN_RIGHT]: null,
        dimensions: {
          height: 26,
          width: 131,
        },
      },
    },
  }
}

const createInitialsElement = (
  counter: number
): IElement<InitialsElementOptions> => {
  const appStore = useAppStore()
  const recipient = fetchPrimaryRecipient(appStore.document?.recipients)
  const primaryRecipient = recipient ? recipient.id : null
  const entityName = recipient?.entityName || null
  const fieldId = `initials_field_${counter}`

  return {
    type: ELEMENTS_LOOKUP.INITIALS_FIELD,
    version: 1,
    id: generateElementId(),
    children: [],
    component: {
      isDraggable: true,
      name: ELEMENTS_NAME[ELEMENTS_LOOKUP.INITIALS_FIELD],
      options: {
        isGhost: true,
        [ELEMENTS_META.TEXT]: 'Initials',
        [ELEMENTS_META.REQUIRED]: true,
        [ELEMENTS_META.FIELD_ID]: fieldId,
        [ELEMENTS_META.SRC]: '',
        [ELEMENTS_META.RECIPIENT]: primaryRecipient,
        [ELEMENTS_META.TIMESTAMP]: null,
        [ELEMENTS_META.ENTITY_NAME]: entityName,
      } as InitialsElementOptions,
    },
    responsiveStyles: {
      large: {
        [ELEMENTS_STYLES.PADDING_TOP]: `0px`,
        [ELEMENTS_STYLES.PADDING_BOTTOM]: `0px`,
        [ELEMENTS_STYLES.PADDING_LEFT]: `0px`,
        [ELEMENTS_STYLES.PADDING_RIGHT]: `0px`,
        [ELEMENTS_STYLES.MARGIN_TOP]: null,
        [ELEMENTS_STYLES.MARGIN_BOTTOM]: null,
        [ELEMENTS_STYLES.MARGIN_LEFT]: null,
        [ELEMENTS_STYLES.MARGIN_RIGHT]: null,
        dimensions: {
          width: 130,
          height: 50,
        },
      },
    },
  }
}

const createCheckBoxElement = (): IElement<CheckBoxElementOptions> => {
  const appStore = useAppStore()
  const builderStore = useBuilderStore()
  const recipient = fetchPrimaryRecipient(appStore.document?.recipients)
  const primaryRecipient = recipient ? recipient.id : null
  const entityName = recipient?.entityName || null
  const fieldId = getNextId(
    builderStore.pages,
    'checkbox_field',
    ELEMENTS_LOOKUP.CHECKBOX
  )

  return {
    type: ELEMENTS_LOOKUP.CHECKBOX,
    version: 1,
    id: generateElementId(),
    children: [],
    component: {
      isDraggable: true,
      name: ELEMENTS_NAME[ELEMENTS_LOOKUP.CHECKBOX],
      options: {
        isGhost: true,
        [ELEMENTS_META.TEXT]: '',
        [ELEMENTS_META.REQUIRED]: true,
        [ELEMENTS_META.PRE_CHECKED]: false,
        [ELEMENTS_META.IS_CONDITIONAL_LOGIC]: false,
        [ELEMENTS_META.FIELD_ID]: fieldId,
        [ELEMENTS_META.SRC]: '',
        [ELEMENTS_META.RECIPIENT]: primaryRecipient,
        [ELEMENTS_META.TIMESTAMP]: null,
        [ELEMENTS_META.ENTITY_NAME]: entityName,
      } as CheckBoxElementOptions,
    },
    responsiveStyles: {
      large: {
        [ELEMENTS_STYLES.PADDING_TOP]: `0px`,
        [ELEMENTS_STYLES.PADDING_BOTTOM]: `0px`,
        [ELEMENTS_STYLES.PADDING_LEFT]: `0px`,
        [ELEMENTS_STYLES.PADDING_RIGHT]: `0px`,
        [ELEMENTS_STYLES.MARGIN_TOP]: null,
        [ELEMENTS_STYLES.MARGIN_BOTTOM]: null,
        [ELEMENTS_STYLES.MARGIN_LEFT]: null,
        [ELEMENTS_STYLES.MARGIN_RIGHT]: null,
        dimensions: {
          width: 25,
          height: 25,
        },
      },
    },
  }
}

const createRowElement = (): IElement => {
  return {
    type: ELEMENTS_LOOKUP.ROW,
    version: 1,
    id: generateElementId(),
    children: [],
    component: {
      name: ELEMENTS_NAME[ELEMENTS_LOOKUP.ROW],
      options: {},
    },
    responsiveStyles: {
      large: {
        [ELEMENTS_STYLES.PADDING_TOP]: `0px`,
        [ELEMENTS_STYLES.PADDING_BOTTOM]: `0px`,
        [ELEMENTS_STYLES.PADDING_LEFT]: `0px`,
        [ELEMENTS_STYLES.PADDING_RIGHT]: `0px`,
        [ELEMENTS_STYLES.MARGIN_TOP]: null,
        [ELEMENTS_STYLES.MARGIN_BOTTOM]: null,
        [ELEMENTS_STYLES.MARGIN_LEFT]: null,
        [ELEMENTS_STYLES.MARGIN_RIGHT]: null,
      },
    },
  }
}

const createColumnElement = (): IElement => {
  return {
    type: ELEMENTS_LOOKUP.COLUMN,
    version: 1,
    id: generateElementId(),
    children: [],
    component: {
      name: ELEMENTS_NAME[ELEMENTS_LOOKUP.COLUMN],
      options: {},
    },
    responsiveStyles: {
      large: {
        [ELEMENTS_STYLES.PADDING_TOP]: `0px`,
        [ELEMENTS_STYLES.PADDING_BOTTOM]: `0px`,
        [ELEMENTS_STYLES.PADDING_LEFT]: `0px`,
        [ELEMENTS_STYLES.PADDING_RIGHT]: `0px`,
        [ELEMENTS_STYLES.MARGIN_TOP]: null,
        [ELEMENTS_STYLES.MARGIN_BOTTOM]: null,
        [ELEMENTS_STYLES.MARGIN_LEFT]: null,
        [ELEMENTS_STYLES.MARGIN_RIGHT]: null,
      },
    },
  }
}
export const createElement = (params: ICreateElement) => {
  const { type } = params
  const { getNextCount } = useTextFieldIdCounter()
  switch (type) {
    case ELEMENTS_LOOKUP.PAGE: {
      return createPage()
    }
    case ELEMENTS_LOOKUP.TEXT: {
      return createTextElement()
    }
    case ELEMENTS_LOOKUP.IMAGE: {
      return createImageElement()
    }
    case ELEMENTS_LOOKUP.VIDEO: {
      return createVideoElement()
    }
    case ELEMENTS_LOOKUP.TABLE: {
      return createTableElement()
    }
    case ELEMENTS_LOOKUP.PAGE_BREAK: {
      return createPageBreakElement()
    }
    case ELEMENTS_LOOKUP.PRODUCT_LIST: {
      return createProductListElement()
    }
    case ELEMENTS_LOOKUP.SIGNATURE: {
      return createSignatureElement()
    }
    case ELEMENTS_LOOKUP.PAYMENT_DETAILS: {
      return createPaymentDetailsElement()
    }
    case ELEMENTS_LOOKUP.TEXT_FIELD: {
      return createTextField(getNextCount())
    }
    case ELEMENTS_LOOKUP.DATE_FIELD: {
      return createDateField(getNextCount())
    }
    case ELEMENTS_LOOKUP.INITIALS_FIELD: {
      return createInitialsElement(getNextCount())
    }
    case ELEMENTS_LOOKUP.CHECKBOX: {
      return createCheckBoxElement()
    }
    case ELEMENTS_LOOKUP.ROW: {
      return createRowElement()
    }
    case ELEMENTS_LOOKUP.COLUMN: {
      return createColumnElement()
    }
  }
}
