import { ccRecipient } from '@gohighlevel/ghl-proposals-common'
import config from '@/config'
import { requests } from '.'

export interface IProposalCustomerCustomNotificationSettings {
  locationId: string
  documentReceivedTemplateId?: string | null
  documentSignedTemplateId?: string | null
  documentSignedTemplateIdForTeam?: string | null
  documentExpiryReminderDefaultTemplateId?: string | null
  enableDocumentReceiveNotification: boolean
  enableCCRecipientNotification: boolean
  enableDocumentSignedNotification: boolean
  enableDocumentSignedForTeamNotification: boolean
  enableDocumentExpiry?: boolean
  enableDocumentExpiryReminderNotification?: boolean
  enableAutoSentInvoice?: boolean
  documentReceivedSubject?: string
  documentSignedSubject?: string
  overrideSignedForTeamEmailSender: boolean
  documentSignedForTeamEmail?: string
  documentSignedForTeamEmailFromName?: string
  documentSignedForTeamSubject?: string
  documentExpiryReminderSubject?: string
  _id?: string
  overrideSignedEmailSender: boolean
  documentSignedEmail?: string
  documentSignedEmailFromName?: string
  overrideReceivedEmailSender: boolean
  documentReceivedEmail?: string
  documentReceivedEmailFromName?: string
  enableDirectPayment: boolean
  ccRecipients?: ccRecipient[]
  documentSignedDefaultTemplateId: string
  documentReceivedDefaultTemplateId: string
  documentExpiryReminderTemplateId: string
  documentExpiryDays?: number
}

export const CustomNotificationService = {
  getCustomNotificationSetting: (params: { locationId: string }) =>
    requests(
      config.PROPOSAL_SERVICE_URL
    ).get<IProposalCustomerCustomNotificationSettings>(
      `/settings/${params?.locationId}`
    ),

  updateCustomNotificationSetting: (
    params: IProposalCustomerCustomNotificationSettings
  ) =>
    requests(
      config.PROPOSAL_SERVICE_URL
    ).put<IProposalCustomerCustomNotificationSettings>(
      '/settings/update',
      params
    ),
}
