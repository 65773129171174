<script setup lang="ts">
import {
  Hourglass03Icon,
  XIcon,
  InfoCircleIcon,
} from '@gohighlevel/ghl-icons/24/outline'
import { ICurrency, PaymentSchedule, PaymentScheduleEvents } from '../../types'
import { ref, PropType, computed } from 'vue'
import { PaymentScheduleModal } from '../modals/PaymentScheduleModal'
import { UITextSmRegular, UITooltip } from '@gohighlevel/ghl-ui'

const emits = defineEmits([
  PaymentScheduleEvents.PAYMENT_SCHEDULE_UPDATED,
  PaymentScheduleEvents.PAYMENT_SCHEDULE_REMOVED,
])
const props = defineProps({
  paymentScheduleInfo: {
    type: Object as PropType<PaymentSchedule> | null | undefined,
    default: null,
  },
  disable: {
    type: Boolean,
    default: false,
  },
  currencyCode: {
    type: String,
    required: true,
  },
  currencyList: {
    type: Object as PropType<{ [key: string]: ICurrency }>,
    default: () => ({}),
  },
  total: {
    type: Number,
    required: true,
  },
  locationId: {
    type: String,
    required: true,
  },
})

const show = ref(false)
const showPaymentScheduleModal = () => {
  if (props.disable) return
  show.value = true
}

const removePaymentSchedule = () => {
  if (props.disable) return
  emits(PaymentScheduleEvents.PAYMENT_SCHEDULE_REMOVED)
}

const addPaymentPlan = (paymentSchedule: PaymentSchedule) => {
  if (props.disable) return
  emits(PaymentScheduleEvents.PAYMENT_SCHEDULE_UPDATED, paymentSchedule)
  onCancel()
}

const onCancel = () => {
  show.value = false
}

const isPaymentScheduleInfoExist = computed(
  () => Object.keys(props.paymentScheduleInfo).length > 0
)
</script>

<template>
  <div>
    <div class="flex justify-between">
      <div
        class="clickable flex items-center"
        :class="
          disable
            ? 'text-gray-500 cursor-not-allowed'
            : 'text-primary-700 cursor-pointer'
        "
        @click="showPaymentScheduleModal"
      >
        <Hourglass03Icon class="h-4 w-4 mr-1 mb-1 inline-block" />
        <UITextSmRegular class="inline-block">{{
          isPaymentScheduleInfoExist
            ? $t('paymentSchedule.changePaymentSchedule')
            : $t('paymentSchedule.addPaymentSchedule')
        }}</UITextSmRegular>

        <UITooltip
          :content="$t('paymentSchedule.addPaymentScheduleWarning')"
          :placement="'top'"
        >
          <template #trigger>
            <InfoCircleIcon class="w-4 h-4 text-gray-500 ml-2" />
          </template>
          {{ $t('paymentSchedule.addPaymentScheduleWarning') }}
        </UITooltip>
      </div>
      <XIcon
        v-if="isPaymentScheduleInfoExist"
        class="h-5 w-5 text-red-400"
        :class="disable ? 'cursor-not-allowed' : 'cursor-pointer'"
        @click="removePaymentSchedule"
      />
    </div>

    <PaymentScheduleModal
      :show="show"
      :paymentScheduleInfo="paymentScheduleInfo"
      :currencyCode="props.currencyCode"
      :currencyList="props.currencyList"
      :total="total"
      @on-cancel="onCancel"
      @on-success="addPaymentPlan"
      :locationId="locationId"
      :disabled="disable"
    />
  </div>
</template>
