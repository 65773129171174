import {
  DiscountType,
  ELEMENTS_LOOKUP,
  IElement,
  ITax,
  LineItem,
  PriceTypeEnum,
  ProductListElementOptions,
  calculator,
  getTaxItemName,
} from '@gohighlevel/ghl-proposals-common'

import { createElement } from '@/helper/element-factory.ts'
import { useBuilderStore } from '@/store/builder'
import { computed } from 'vue'

export const useLineItems = (elementId: string) => {
  const store = useBuilderStore()
  const element = computed(
    () =>
      store.getElementById(elementId)
        ?.element as IElement<ProductListElementOptions>
  )
  const productLineOptions = computed((): ProductListElementOptions => {
    return (
      element?.value?.component?.options ||
      createElement({ type: ELEMENTS_LOOKUP.PRODUCT_LIST })
    )
  })

  const productListVersion = computed(() => element?.value?.version)

  const selectedLineItems = computed(() => {
    if (element?.value && element?.value?.version > 1) {
      return productLineOptions.value.lineItems.filter(
        ({ selected }) => selected
      )
    }
    return productLineOptions.value.lineItems
  })

  const recurringLineItemsSelected = computed(() => {
    if (element?.value) {
      return productLineOptions.value.lineItems.filter(
        ({ priceType, selected, isSetupFeeItem }) =>
          priceType === PriceTypeEnum.RECURRING && selected && !isSetupFeeItem
      )
    }
    return []
  })

  const subTotalRecurringLineItems = computed(() => {
    return Number(
      recurringLineItemsSelected.value
        ?.reduce((acc: number, { price, qty }: LineItem) => {
          acc += price * qty
          return acc
        }, 0)
        ?.toFixed(2)
    )
  })

  const subTotal = computed(() => {
    return Number(
      selectedLineItems.value
        ?.reduce((acc: number, { price, qty }: LineItem) => {
          acc += price * qty
          return acc
        }, 0)
        ?.toFixed(2)
    )
  })

  const totalDiscount = computed(() => {
    if (productLineOptions.value.discountType === DiscountType.PERCENTAGE) {
      const { percentageOf } = calculator(
        subTotal.value,
        element?.value?.version
      )
      return Number(
        percentageOf(
          Number(productLineOptions.value?.discountValue?.toFixed(2))
        )
      )
    }
    return Number(productLineOptions.value?.discountValue?.toFixed(2))
  })

  const amountDue = computed(() => {
    const value = Number(
      (
        subTotal.value -
        totalDiscount.value +
        appliedTax.value.reduce((acc, { tax, taxInclusive }) => {
          return taxInclusive ? acc : acc + tax
        }, 0)
      )?.toFixed(2)
    )
    return value
  })

  const paymentSchedules = computed(
    () => productLineOptions?.value?.paymentSchedules
  )

  const getDiscountAmountPerItem = (itemPrice: number) => {
    if (productLineOptions.value.discountType === DiscountType.PERCENTAGE) {
      const { percentageOf } = calculator(itemPrice, element?.value?.version)
      return (
        itemPrice -
        percentageOf(Number(productLineOptions.value?.discountValue))
      )
    } else {
      const totalDiscount = Number(productLineOptions.value?.discountValue)
      const discountRatio = itemPrice / subTotal.value
      const itemDiscount = discountRatio * totalDiscount
      const discountedPrice = itemPrice - itemDiscount
      return discountedPrice
    }
  }

  const appliedTax = computed(() => {
    return Object.values(
      selectedLineItems.value?.reduce((acc, lineItem) => {
        const isTaxInclusive = !!lineItem?.taxInclusive
        let itemPrice = lineItem.price

        if (isTaxInclusive) {
          const totalTaxRate =
            lineItem.taxes?.reduce((acc, tax) => acc + tax.rate, 0) || 0
          const { percentageOf } = calculator(
            itemPrice,
            element?.value?.version
          )

          // Calculate the included tax on the item based on the total tax rate
          const includedTaxOnItem = percentageOf(
            (totalTaxRate / (100 + totalTaxRate)) * 100
          )

          // Calculate the actual item price by subtracting the included tax from the item price
          const actualItemPrice = itemPrice - includedTaxOnItem
          itemPrice = actualItemPrice
        }

        const itemSubTotal = getDiscountAmountPerItem(itemPrice * lineItem.qty)

        const { percentageOf } = calculator(
          itemSubTotal,
          element?.value?.version
        )
        lineItem.taxes?.forEach((tax: ITax) => {
          const taxKey = `${tax._id}_taxInclusive_${isTaxInclusive}`
          if (acc[taxKey]) {
            acc[taxKey] = {
              ...acc[taxKey],
              name: getTaxItemName(tax, isTaxInclusive),
              tax: acc[taxKey]['tax'] + percentageOf(tax.rate),
              taxInclusive: isTaxInclusive,
            }
          } else {
            acc[taxKey] = {
              name: getTaxItemName(tax, isTaxInclusive),
              description: tax.description,
              _id: tax._id,
              rate: tax.rate,
              tax: percentageOf(tax.rate),
              taxInclusive: isTaxInclusive,
            }
          }
        })
        return acc
      }, {} as Record<string, Pick<ITax, '_id' | 'description' | 'name' | 'rate'> & { tax: number; taxInclusive: boolean }>) ||
        []
    )
  })

  return {
    subTotal,
    amountDue,
    totalDiscount,
    paymentSchedules,
    productLineOptions,
    appliedTax,
    productListVersion,
    subTotalRecurringLineItems,
  }
}
