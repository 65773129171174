<script setup lang="ts">
import { useActionBarStore } from '@/store/actionBar'
import { useBuilderStore } from '@/store/builder'

import {
  UIDrawer,
  UIDrawerContent,
  UISelect,
  UIFormItem,
  UIInput,
  UITooltip,
  UIDivider,
  UIInputGroup,
  UIRadioGroup,
  UIRadio,
} from '@gohighlevel/ghl-ui'
import { InfoCircleIcon } from '@gohighlevel/ghl-icons/24/outline'

import { onMounted, reactive, ref, watch } from 'vue'
import { useEmailTemplates, useCustomNotificationSettings } from '@/composition'

import {
  InputWithCustomValues,
  validateEmail,
  Switch,
  redirectTypes,
  isValidUrl,
} from '@gohighlevel/ghl-proposals-common'
import { useAppStore } from '@/store/app'
import { isEmpty, cloneDeep, isNil } from 'lodash'
import { IProposalCustomerCustomNotificationSettings } from '@gohighlevel/ghl-proposals-common/src/services/CustomNotificationService'

const store = useBuilderStore()
const appStore = useAppStore()
const storeAction = useActionBarStore()
const { emailTemplateList, fetchEmailTemplates } = useEmailTemplates()
const { notificationSetting, fetchCustomNotificationSetting } =
  useCustomNotificationSettings()
interface NotificationSettings {
  _id?: string
  [key: string]: any
}

const previousSettings = ref<NotificationSettings>({})
const isSubjectEdited = ref(false)
const notificationSettings = reactive({
  overrideDocumentNotificationSettings:
    appStore.document?.notificationSettings?.sender &&
    (!isNil(appStore.document?.notificationSettings?.sender?.fromEmail) ||
      !isNil(appStore.document?.notificationSettings?.sender?.fromName))
      ? true
      : false,
  enableDocumentReceiveNotification: appStore.document.notificationSettings
    ?.receive
    ? true
    : notificationSetting.value.enableDocumentReceiveNotification,
  overrideReceivedEmailSender:
    appStore.document.notificationSettings?.sender?.fromEmail ||
    notificationSetting.value.overrideReceivedEmailSender,
})

const templates = reactive({
  documentReceivedTemplateId:
    appStore.document.notificationSettings?.receive?.templateId ||
    notificationSetting.value.documentReceivedTemplateId,
  documentReceivedSubject:
    appStore.document.notificationSettings?.receive?.subject ||
    notificationSetting.value.documentReceivedSubject,
  documentReceivedEmail:
    appStore.document.notificationSettings?.sender?.fromEmail ||
    notificationSetting.value.documentReceivedEmail,
  documentReceivedEmailFromName:
    appStore.document.notificationSettings?.sender?.fromName ||
    notificationSetting.value.documentReceivedEmailFromName,
})

const redirectSettings = reactive({
  enableDocumentRedirectUrl:
    appStore.document.redirectSettings?.enableDocumentRedirectUrl || false,
  documentRedirectUrl: appStore.document.redirectSettings?.documentRedirectUrl,
  documentRedirectType:
    appStore.document.redirectSettings?.documentRedirectType,
})

onMounted(() => {
  fetchCustomNotificationSetting()
  fetchEmailTemplates()
})

watch(
  () => appStore.document.notificationSettings,
  newSettings => {
    delete newSettings?.sender?.value
    if (!isNil(newSettings?.receive?.templateId)) {
      templates.documentReceivedTemplateId = newSettings.receive.templateId
    } else {
      templates.documentReceivedTemplateId =
        notificationSetting.value.documentReceivedTemplateId
    }

    if (!isNil(newSettings?.receive?.subject)) {
      templates.documentReceivedSubject = newSettings.receive.subject
    } else {
      templates.documentReceivedSubject =
        notificationSetting.value.documentReceivedSubject
    }

    if (!isNil(newSettings?.sender?.fromName)) {
      templates.documentReceivedEmailFromName = newSettings.sender.fromName
    } else {
      templates.documentReceivedEmailFromName =
        notificationSetting.value.documentReceivedEmailFromName
    }

    if (!isNil(newSettings?.sender?.fromEmail)) {
      templates.documentReceivedEmail = newSettings.sender.fromEmail
    } else {
      templates.documentReceivedEmail =
        notificationSetting.value.documentReceivedEmail
    }
  },
  { deep: true }
)

const validateField = (field: string, value: string) => {
  const specialCharRegex = /[^a-zA-Z0-9\s]/

  if (
    !notificationSettings.overrideDocumentNotificationSettings ||
    !appStore.sendAttempted
  ) {
    return ''
  }

  switch (field) {
    case 'documentReceivedEmailFromName':
      if (!value) {
        return 'Sender Name is required'
      }
      if (specialCharRegex.test(value)) {
        return 'Name format is incorrect'
      }
      break
    case 'documentReceivedEmail':
      if (!value) {
        return 'Email is required'
      } else if (!validateEmail(value)) {
        return 'Invalid Email Format'
      }
      break
    case 'documentReceivedSubject':
      if (!value && isSubjectEdited.value) {
        return 'Subject is required'
      }
      break
  }
  return ''
}

const onUpdate = (val: string, key: keyof typeof templates) => {
  templates[key] = val
  if (key === 'documentReceivedSubject') {
    isSubjectEdited.value = true
    appStore.updateDocumentNotificationReceiveSettings({
      receive: {
        subject: val,
      },
    })
  } else if (key === 'documentReceivedTemplateId') {
    appStore.updateDocumentNotificationReceiveSettings({
      receive: {
        templateId: val,
      },
    })
  } else if (key === 'documentReceivedEmail') {
    appStore.updateDocumentNotificationSenderSettings({
      sender: {
        fromEmail: val,
      },
    })
  } else if (key === 'documentReceivedEmailFromName') {
    appStore.updateDocumentNotificationSenderSettings({
      sender: {
        fromName: val,
      },
    })
  }
}

const DefaultSenderNotificationSettings = () => {
  const payload = {
    sender: {
      fromEmail: templates.documentReceivedEmail || '',
      fromName: templates.documentReceivedEmailFromName || '',
    },
  }
  return payload
}

const onToggleSwitch = (value: boolean) => {
  notificationSettings.overrideDocumentNotificationSettings = value

  // In case of disabling the switch, store the current settings and clear them
  if (!value) {
    previousSettings.value = cloneDeep({
      sender: {
        fromName: templates.documentReceivedEmailFromName,
        fromEmail: templates.documentReceivedEmail,
      },
    })
    appStore.updateDocumentNotificationSenderSettings({ sender: {} })
  } else {
    // Restore the previous settings if available else set global settings as the settings for the document
    if (!isEmpty(previousSettings.value)) {
      appStore.updateDocumentNotificationSenderSettings(previousSettings.value)
      previousSettings.value = {}
    } else {
      appStore.updateDocumentNotificationSenderSettings(
        DefaultSenderNotificationSettings()
      )
    }

    appStore.setUnsavedChanges(true)
  }
}

const onUpdateRedirectSettings = (
  val: string,
  key: keyof Pick<
    IProposalCustomerCustomNotificationSettings,
    'documentRedirectUrl' | 'documentRedirectType'
  >
) => {
  redirectSettings[key] = val
  appStore.setRedirectSettings(redirectSettings)
}

const handleRedirectTypeChange = (event: any) => {
  onUpdateRedirectSettings(event.target.value, 'documentRedirectType')
}

const handleEnableRedirectUrl = () => {
  appStore.setRedirectSettings(redirectSettings)
}

const onClose = () => {
  storeAction.toggleDocumentSettings()
}

const validateRedirectUrl = () => {
  if (!appStore.sendAttempted) {
    return ''
  }

  if (
    redirectSettings.enableDocumentRedirectUrl &&
    !redirectSettings.documentRedirectUrl
  ) {
    return 'Redirect URL is required'
  }

  const url = redirectSettings.documentRedirectUrl || ''
  const replacedUrl = /^https?:\/\//i.test(url) ? `${url}` : `https://${url}`
  if (
    redirectSettings.enableDocumentRedirectUrl &&
    redirectSettings?.documentRedirectUrl &&
    !isValidUrl(replacedUrl)
  ) {
    return 'Invalid URL format'
  }
  return ''
}
</script>

<template>
  <UIDrawer
    :width="380"
    :trap-focus="false"
    id="document-settings-drawer"
    :show="storeAction.documentSettings"
    @update:show="onClose"
    :placement="'left'"
    to="#drawer-target"
    class="!shadow-page h-screen"
  >
    <UIDrawerContent
      id="document-settings-drawer-content"
      :title="$t('common.documentSettings')"
    >
      <div class="relative">
        <div class="relative">
          <Switch
            :value="notificationSettings.overrideDocumentNotificationSettings"
            :label="`Override Email Configuration`"
            :infoLabel="``"
            @update:value="onToggleSwitch"
            :disabled="!store.isDocumentEditable"
            class="mb-8"
          ></Switch>
          <div class="absolute top-0 left-[83%]">
            <UITooltip
              :content="`Override global settings for name and email for notifications on this document`"
              :placement="'top'"
            >
              <template #trigger>
                <InfoCircleIcon class="w-4 h-4 text-gray-500" />
              </template>
              Override global settings for name and email for notifications on
              this document
            </UITooltip>
          </div>
        </div>
        <div class="w-full">
          <div class="w-full flex justify-start">
            <UIFormItem label="From Name" :showFeedback="false" class="w-full">
              <UIInput
                id="document_received_from_name"
                v-model:value="templates.documentReceivedEmailFromName"
                @update:value="
                  value => onUpdate(value, 'documentReceivedEmailFromName')
                "
                :disabled="
                  !store.isDocumentEditable ||
                  (store.isDocumentEditable &&
                    !notificationSettings.overrideDocumentNotificationSettings)
                "
                class="ml-auto"
              />
            </UIFormItem>
          </div>
          <span
            v-if="
              validateField(
                'documentReceivedEmailFromName',
                templates.documentReceivedEmailFromName
              )
            "
            class="text-sm text-red-500 block w-full ml-auto"
            >{{
              validateField(
                'documentReceivedEmailFromName',
                templates.documentReceivedEmailFromName
              )
            }}</span
          >
          <div class="w-full mt-4 flex justify-start">
            <UIFormItem label="From Email" :showFeedback="false" class="w-full">
              <UIInput
                id="document_received_from_email"
                v-model:value="templates.documentReceivedEmail"
                @update:value="
                  value => onUpdate(value, 'documentReceivedEmail')
                "
                :disabled="
                  !store.isDocumentEditable ||
                  (store.isDocumentEditable &&
                    !notificationSettings.overrideDocumentNotificationSettings)
                "
                class="ml-auto"
              />
            </UIFormItem>
          </div>
          <span
            v-if="
              validateField(
                'documentReceivedEmail',
                templates.documentReceivedEmail
              )
            "
            class="text-sm text-red-500 block w-full ml-auto"
            >{{
              validateField(
                'documentReceivedEmail',
                templates.documentReceivedEmail
              )
            }}</span
          >
          <UIDivider />
          <div class="w-full mt-4">
            <InputWithCustomValues
              id="document_received"
              label="Email Subject"
              v-model:value="templates.documentReceivedSubject"
              @update:value="
                value => onUpdate(value, 'documentReceivedSubject')
              "
              :disabled="!store.isDocumentEditable"
              :showFeedback="false"
            />
          </div>
          <span
            v-if="
              validateField(
                'documentReceivedSubject',
                templates.documentReceivedSubject
              )
            "
            class="text-sm text-red-500 block"
            >{{
              validateField(
                'documentReceivedSubject',
                templates.documentReceivedSubject
              )
            }}</span
          >
          <div class="w-full mt-4">
            <UIFormItem
              :label="$t('proposalsList.emailTemplate')"
              :showFeedback="false"
            >
              <UISelect
                id="email-template-for-document-rec"
                v-model:value="templates.documentReceivedTemplateId"
                :options="emailTemplateList"
                :placeholder="$t('proposalsList.selectTemplate')"
                @update:value="
                  value => onUpdate(value, 'documentReceivedTemplateId')
                "
                :disabled="!store.isDocumentEditable"
              />
            </UIFormItem>
          </div>
        </div>
      </div>

      <UIDivider class="mt-2" />

      <Switch
        v-model:value="redirectSettings.enableDocumentRedirectUrl"
        :label="$t('common.enableRedirectionTitle')"
        :infoLabel="``"
        :disabled="!store.isDocumentEditable"
        @update:value="handleEnableRedirectUrl"
        class="mb-8"
      ></Switch>

      <div class="bg-gray-100 p-2 rounded-sm">
        <div class="w-full mb-1">
          <UIFormItem
            :label="$t('common.enterCustomUrl')"
            class="mt-2 text-sm text-gray-700"
            :style="{
              '--n-label-text-color':
                !store.isDocumentEditable ||
                !redirectSettings.enableDocumentRedirectUrl
                  ? 'rgba(194, 194, 194, 1)'
                  : 'inherit',
            }"
            :showFeedback="validateRedirectUrl() ? false : true"
          >
            <UIInputGroup>
              <UIInput
                v-model:value="redirectSettings.documentRedirectUrl"
                @update:value="
                  value =>
                    onUpdateRedirectSettings(value, 'documentRedirectUrl')
                "
                id="document-settings-redirectUrl"
                placeholder="https://example.com"
                type="text"
                :disabled="
                  !store.isDocumentEditable ||
                  !redirectSettings.enableDocumentRedirectUrl
                "
              />
            </UIInputGroup>
          </UIFormItem>
          <span
            v-if="validateRedirectUrl()"
            class="text-sm text-red-500 block"
            >{{ validateRedirectUrl() }}</span
          >
        </div>

        <div class="w-full pb-3">
          <UIRadioGroup
            :name="'radioGroupRedirectUrlDocumentSettings'"
            :disabled="
              !store.isDocumentEditable ||
              !redirectSettings.enableDocumentRedirectUrl
            "
            class="flex justify-between"
            :value="redirectSettings.documentRedirectType"
          >
            <UIRadio
              class="redirect-type-radio"
              :id="`redirect-type-${redirectType.value}`"
              v-for="redirectType in redirectTypes"
              :key="redirectType.value"
              :value="redirectType.value"
              :disabled="!store.isDocumentEditable"
              :label="redirectType.label"
              @change="handleRedirectTypeChange"
            />
          </UIRadioGroup>
        </div>
      </div>
    </UIDrawerContent>
  </UIDrawer>
</template>

<style lang="scss" scoped>
.text-red-500 {
  display: block;
}

.redirect-type-radio {
  --n-font-size: 14px !important;
}
</style>
