export enum SendDocumentsContractAction {
  EMAIL = 'email',
  SMS = 'sms',
  SMS_AND_EMAIL = 'sms_and_email',
  SEND_MANUALLY = 'send_manually',
}

export type GenericEvent<T> = Event & {
  target: T
}

export enum CCSendDocumentModalEvents {
  ON_UPDATE_DOCUMENT_TITLE = 'on-update-document-title',
}

export enum EmailTemplatePreviewEvents {
  EDIT_EMAIL_TEMPLATE_DOCUMENT_CONTRACT_FROM_PREVIEW = 'edit-email-template-document-contract-from-preview',
  EDIT_EMAIL_TEMPLATE_DOCUMENT_CONTRACT = 'edit-email-template-document-contract',
  EDIT_EMAIL_TEMPLATE_CLOSED_DOCUMENT_CONTRACT = 'edit-email-template-closed-documents-contracts',
}

export enum ProposalsMailTemplates {
  SEND_PROPOSAL = 'sendProposal',
  PROPOSAL_ACCEPTED_TO_RECIPIENT = 'proposalAcceptedToRecipient',
  PROPOSAL_EXPIRY_REMINDER = 'proposalExpiryReminderToRecipient',
}
