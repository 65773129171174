<script lang="ts" setup>
import { PropType, computed, onMounted } from 'vue'
import { UICheckbox, UITextSmRegular, UITooltip } from '@gohighlevel/ghl-ui'
import {
  CheckBoxElementOptions,
  GroupFields,
  GroupFieldsOptions,
  IElement,
  IRecipient,
  MovableComponentEvents,
} from '../../types'
import { ELEMENTS_META } from '../../const'
import { MovableComponentWrapper } from '../MovableComponentWrapper'
import { CommonTooltip } from '../Tooltip'
import { PlusSquareIcon } from '@gohighlevel/ghl-icons/24/outline'
import { getSignatureElementColorStyles } from '../../utils'

const props = defineProps({
  element: Object as PropType<IElement<CheckBoxElementOptions>>,
  isActive: {
    type: Boolean,
    default: false,
  },
  snapContainer: {
    type: String,
  },
  boundContainer: {
    type: String,
  },
  storeMap: {
    type: Object,
    required: true,
  },
  compositionMap: {
    type: Object,
    required: true,
  },
  pageId: {
    type: String,
    required: false,
  },
  pageIndex: {
    type: String,
    required: false,
  },
})

const isActiveElement = computed(() => props.isActive)
const builderStore = props.storeMap.useBuilderStore()
const store = props.storeMap.useAppStore()
const emits = defineEmits([
  MovableComponentEvents.ON_ACTIVE,
  MovableComponentEvents.ON_CLONE_AND_ADD_TO_GROUP,
  MovableComponentEvents.ON_CLICK_GROUP,
])
const { updateStyles, updateOption } = props.compositionMap.useElementOption()

const elementGroup = computed(() => {
  return builderStore.getElementGroup(props.element?.id)
})

const recipient = computed(() => {
  const recipients = store.document?.recipients || []
  return (
    recipients.find(
      ({ id }) => id === props.element?.component?.options?.recipient
    ) || ({} as IRecipient)
  )
})

const recipientName = computed(() => {
  const { firstName, lastName } = recipient.value
  return firstName ? `${firstName} ${lastName || ''}` : ''
})

const onDrag = ({ top, left }: { top: number; left: number }) => {
  updateStyles(
    'position',
    {
      top,
      left,
    },
    props.element?.id
  )
  updateOption('isGhost', true, props.element?.id)
}

const onScale = ({ scaleX, scaleY }: { scaleX: number; scaleY: number }) => {
  updateStyles('scale', { scaleX, scaleY }, props.element?.id)
}
const onResize = ({ height, width }: { height: number; width: number }) => {
  updateStyles('dimensions', { height, width }, props.element?.id)
}
const onActive = (e: any, isActive: boolean) => {
  emits(MovableComponentEvents.ON_ACTIVE, e, isActive)
}
const position = computed(() => {
  const pos = props.element?.responsiveStyles.large.position
  return pos || { top: 0, left: 0, bottom: 0 }
})
const scale = computed(() => {
  const scaleObj = props.element?.responsiveStyles.large.scale
  return scaleObj || { scaleX: 1, scaleY: 1 }
})
const align = computed(() => props.element?.responsiveStyles.large.align)
const dimensions = computed(() => {
  const dimensionsObj = props.element?.responsiveStyles.large.dimensions
  if (dimensionsObj?.height && dimensionsObj?.width) {
    return {
      height: dimensionsObj.height + 'px',
      width: dimensionsObj.width + 'px',
    }
  } else {
    return { height: 'auto', width: 'auto' }
  }
})

const colorStyles = computed(() => {
  const dimensionsObj = dimensions.value

  return {
    ...recipientColorStyles.value,
    ...styles.value,
    outline: `1px solid ${recipientColorStyles.value.borderColor}`,
    borderRadius: '6px',
    color: recipientColorStyles.value.placeholderColor,
    'box-shadow': 'none !important',
    overflow: 'hidden',
    padding: '5px',
    ...(dimensionsObj
      ? { height: `${dimensionsObj.height}`, width: `${dimensionsObj.width}` }
      : {}),
  }
})

function clone(e: MouseEvent) {
  e.stopImmediatePropagation()
  builderStore.cloneElement(props?.element?.id as string)
}

function del(e: any) {
  e.stopPropagation()
  builderStore.deleteElement(props?.element?.id as string)
  builderStore.syncRecipient()
}

onMounted(() => {
  builderStore.syncRecipient()
})
const createGroup = (event: MouseEvent) => {
  event.stopPropagation()
  emits(MovableComponentEvents.ON_CLONE_AND_ADD_TO_GROUP, props?.element?.id)
}

const onClickGroup = (
  group: GroupFields<GroupFieldsOptions>,
  elementId: string
) => {
  emits(MovableComponentEvents.ON_CLICK_GROUP, group, elementId)
}

const recipientColorStyles = computed(() => {
  return getSignatureElementColorStyles(recipient.value)
})

const styles = computed(() => {
  const stylesObj: Record<string, string> = {}
  if (scale.value) {
    stylesObj[
      'transform'
    ] = `scale(${scale.value.scaleX}, ${scale.value.scaleY})`
  }
  if (position.value) {
    stylesObj['top'] = `${position.value.top}px`
    stylesObj['left'] = `${position.value.left}px`
    stylesObj['zIndex'] = '100'
  }

  if (dimensions.value) {
    if (dimensions?.value.height !== 'auto') {
      stylesObj['height'] = `${dimensions.value.height}px`
    }
    if (dimensions?.value.width !== 'auto') {
      stylesObj['width'] = `${dimensions.value.width}px`
    }
  }
  return stylesObj
})

const isChecked = computed(() => {
  if (
    props.element?.component.options.text === 'true' ||
    props.element?.component.options.preChecked
  ) {
    return true
  }
  return false
})

const updateCheckbox = (val: boolean) => {
  if (!elementGroup.value) {
    updateOption(ELEMENTS_META.PRE_CHECKED, val, props.element?.id)
    updateOption(ELEMENTS_META.TEXT, val.toString(), props.element?.id)
  }
}
</script>

<template>
  <MovableComponentWrapper
    :pageIndex="props.pageIndex"
    :pageId="props.pageId"
    :key="`moveable-element-${props.element?.id}`"
    :id="(props.element?.id as string)"
    @on-drag="onDrag"
    @on-scale="onScale"
    @on-active="onActive"
    @on-resize="onResize"
    :isActiveElement="isActiveElement"
    :isEditable="builderStore.isDocumentEditable"
    :position="position"
    :scale="scale"
    :align="align"
    :dimensions="props.element?.responsiveStyles.large.dimensions"
    :isGhost="props.element?.component.options.isGhost"
    :snap-container="props.snapContainer"
    :bound-container="props.boundContainer"
    :isDragElement="props.element?.component.isDraggable"
    :resizable="true"
    :keep-ratio="true"
    :render-directions="['se']"
    :colorOverride="recipientColorStyles.placeholderColor"
    :inactiveColorOverride="recipientColorStyles.borderColor"
    :isSelectoField="true"
    :elementGroup="elementGroup"
    @on-click-group="onClickGroup"
    :min-height="20"
    :max-height="50"
    :disable-nested-moveable="true"
  >
    <template #component>
      <span
        v-if="!elementGroup && element?.component.options.required"
        class="option--required"
        >*</span
      >
      <div class="el-wrapper checkbox-field">
        <UICheckbox
          :checked="isChecked"
          :style="colorStyles"
          :key="props.element?.id"
          :id="`floating_checkbox_field-${props.element?.id}`"
          :type="'default'"
          @update:checked="updateCheckbox"
        />
      </div>
    </template>
    <template #tooltip v-if="recipientName">
      <UITextSmRegular
        class="capitalize font-semibold py-1 px-4 text-xs"
        :style="{ color: recipientColorStyles.placeholderColor }"
        >{{ recipientName }}</UITextSmRegular
      >
    </template>
    <template #popup-options>
      <CommonTooltip @on-clone="clone" @on-delete="del" />
    </template>
    <template #group-options>
      <UITooltip
        :key="`add-to-checkbox-element-${props.element?.id}`"
        v-if="!elementGroup"
        :placement="'bottom'"
      >
        <template #trigger>
          <div
            class="text-primary-700 cursor-pointer flex"
            @click="createGroup"
          >
            <PlusSquareIcon class="w-6 h-6 p-1" />
          </div>
        </template>
        {{ $t('common.addCheckboxToGroup') }}
      </UITooltip>
    </template>
  </MovableComponentWrapper>
</template>

<style lang="scss">
.checkbox-field {
  width: v-bind('dimensions.width') !important;
  height: v-bind('dimensions.height') !important;
  .n-checkbox {
    padding: 0 !important;
    width: v-bind('dimensions.width') !important;
    height: v-bind('dimensions.height') !important;
    --n-size: v-bind('dimensions.width') !important;
  }
}
</style>
